import API_URL from "./apiConfig/api.config";
import apiCall from "./apiConfig/apiCall.config";

export async function getCategories(pageNo?: number) {
  try {
    let url: string = API_URL.VIDEO.CATEGORY;
    if (pageNo) url += "?page=" + pageNo;
    return await apiCall.get(url);
  } catch (e) {
    throw e;
  }
}

export async function getSasUrl() {
  try {
    return await apiCall.get(API_URL.VIDEO.CREATE_ASSET);
  } catch (e) {
    throw e;
  }
}

export async function putVideBlocks(uri: string, data: any, header: any) {
  try {
    return await apiCall.put(uri, data, header);
  } catch (e) {
    throw e;
  }
}

export async function uploadStory(data: any) {
  try {
    return await apiCall.post(API_URL.VIDEO.UPLOAD_STORY, data);
  } catch (e) {
    throw e;
  }
}
export async function getStoryList(data: any, pageNo?: number) {
  try {
    let url: string = API_URL.VIDEO.STORY_LIST;
    if (pageNo) url += "?page=" + pageNo;
    return await apiCall.post(url, data);
  } catch (e) {
    throw e;
  }
}
