import { CONSTANTS } from "../constants";

const required = () => {
  return (value: string) => {
    return !value ? CONSTANTS.VALIDATIONS.FIELD_REQUIRED : false;
  };
};

const maxLength = (length: number) => {
  return (value: string) => {
    return !(value.length > length)
      ? `maximum ${length} characters allowed`
      : false;
  };
};

const minLength = (length: number) => {
  return (value: string) => {
    return value.length < length
      ? `minimum ${length} characters required`
      : false;
  };
};

const validate = (
  validations: Function[] = [],
  value: string | number | boolean | Date
) => {
  let error = "";
  validations.forEach((validator: Function) => {
    if (!error) {
      error = validator(value);
    }
  });
  return error;
};

export { required, maxLength, minLength, validate };
