import React, { useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";

import { NewStoryVideoCapture } from "../../../types/newStory";
import { timerInitVal } from "../../../model/profile.model";
import ProgressBar from "../ProgressBar/ProgressBar";
import commonService from "../../../services/common.service";

const VideoCapture: React.FunctionComponent<any> = (
  props: NewStoryVideoCapture
) => {
  const webcamRef = useRef<any>(null);
  const mediaRecorderRef = useRef<any>(null);
  const recordingState = useRef<string>("");
  const timer = useRef<any>(commonService.cloneDeep(timerInitVal));
  const [capturing, setCapturing] = useState(false);
  const [isPause, setPause] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState<any>([]);
  const [progressBarDetails, setProgressBarDetails] = useState<any>({
    totalTime: 0,
    width: 0,
    text: "05:00",
  });

  useEffect(() => {
    return () => {
      clearInterval(timer?.current?.cFlag);
      timer.current = commonService.cloneDeep(timerInitVal);
    };
  }, []);

  useEffect(() => {
    if (recordingState.current === "stopped" && recordedChunks.length > 0) {
      handleUpload();
      recordingState.current = "";
    }
  }, [recordedChunks]);

  const getMimeType = () => {
    let mimeType: any = "";
    if (MediaRecorder.isTypeSupported("video/webm; codecs=vp9")) {
      mimeType = "video/webm";
    } else if (MediaRecorder.isTypeSupported("video/webm")) {
      mimeType = "video/webm";
    } else if (MediaRecorder.isTypeSupported("video/mp4")) {
      mimeType = "video/mp4";
    } else {
      console.error("no suitable mimetype found for this device");
    }

    return mimeType;
  };

  const getMediaRecorderOptions = () => {
    let options: any = "";
    if (MediaRecorder.isTypeSupported("video/mp4")) {
      options = { mimeType: "video/mp4", videoBitsPerSecond: 100000 };
    } else {
      options = { mimeType: getMimeType() };
    }
    return options;
  };

  const handleCancel = () => {
    toggleTimer(false);
    props.handleCancel();
  };

  const handleDataAvailable = React.useCallback(
    ({ data }: any) => {
      if (data.size > 0) {
        setRecordedChunks((prev: any) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: getMimeType(),
      });
      const url = URL.createObjectURL(blob);
      const a: any = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  const handlePauseCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.pause();
    setPause(true);
    recordingState.current = "paused";
  }, [mediaRecorderRef, setCapturing]);

  const handleResumeCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.resume();
    setPause(false);
    recordingState.current = "recording";
  }, [mediaRecorderRef, setCapturing]);

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(
      webcamRef.current?.stream || {},
      getMediaRecorderOptions()
    );
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
    recordingState.current = "recording";
    toggleTimer();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setPause(false);
    recordingState.current = "stopped";
    toggleTimer(false);
  }, [mediaRecorderRef, setCapturing]);

  const handleUpload = React.useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: getMimeType(),
      });
      props.handleCapture(blob);
    }
  }, [recordedChunks]);

  const toggleTimer = (state: boolean = true) => {
    if (state)
      timer.current.cFlag = setInterval(() => {
        if (recordingState.current === "paused") return;
        const elapsedTime: number = timer.current.elapsedTime + 1000;
        timer.current.elapsedTime = elapsedTime;
        const percentage: number = Math.ceil(
          (elapsedTime / timer.current.timeTotal) * 100
        );
        const elapsedTimeInSec: number = elapsedTime / 1000;
        const elapsedTimeInMin: number =
          elapsedTimeInSec > 60 ? elapsedTimeInSec / 60 : 0;
        const roundedSec: number =
          elapsedTimeInSec > 60 ? elapsedTimeInSec % 60 : elapsedTimeInSec;
        const formattedSec: string = ("0" + roundedSec).slice(-2);
        const formattedMin: string = (
          "0" +
          Math.ceil(
            elapsedTimeInMin > 1 ? elapsedTimeInMin - 1 : elapsedTimeInMin
          )
        ).slice(-2);

        const timerDetails: any = {
          totalTime: elapsedTime,
          width: percentage >= 100 ? "100%" : percentage + "%",
          text: formattedMin + ":" + formattedSec,
        };
        setProgressBarDetails(timerDetails);
      }, 1000);
    else {
      clearInterval(timer.current.cFlag);
      timer.current = commonService.cloneDeep(timerInitVal);
    }
  };

  const videoConstraints: any = {
    width: 1920,
    height: 1080,
    facingMode: "user",
  };
  const audioConstraints: any = {
    sampleSize: 16,
    channelCount: 2,
    echoCancellation: true,
  };

  return (
    <div
      className="uploading upload-form-mystory container "
      id="upload-form-mystory"
    >
      <div className="row">
        <div className="col-lg-6 video-form-res">
          <h3 className="text-start">Preview</h3>
          <div className="custom-video-player mb-3 st-video-recorder">
            <Webcam
              ref={webcamRef}
              audio
              muted
              videoConstraints={videoConstraints}
              audioConstraints={audioConstraints}
            />
          </div>
          <div className=" mb-3 d-flex align-items-center">
            <ProgressBar fill={progressBarDetails.width} />
            <div className="text-white ps-2">{progressBarDetails.text}</div>
          </div>
          <div className="d-flex justify-content-between flex-lg-row flex-column  w-100 w-lg-auto">
            {capturing ? (
              <>
                <button
                  className="st-btn st-btn-primary mb-2"
                  onClick={() =>
                    isPause
                      ? handleResumeCaptureClick()
                      : handlePauseCaptureClick()
                  }
                >
                  {!isPause ? "Pause recording" : "Resume recording"}
                </button>
                <button
                  className="st-btn st-btn-primary mb-2"
                  onClick={handleStopCaptureClick}
                >
                  Stop capture
                </button>
              </>
            ) : (
              <>
                <button
                  onClick={handleStartCaptureClick}
                  className="st-btn st-btn-primary mb-2"
                >
                  Start capture
                </button>
                <button
                  className="st-btn st-btn-primary mb-2"
                  onClick={() => handleCancel()}
                >
                  Cancel
                </button>
              </>
            )}
            {recordedChunks.length > 0 && (
              <>
                <button onClick={handleDownload}>Download</button>
                <button onClick={handleUpload}>Upload</button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCapture;
