import {
  forgotPassword,
  getAccessToken,
  getMyStories,
  getProfileDetails,
  registerUser,
  resetPassword,
  updateProfile,
  verifyEmail,
  contactUs,
  resentVerification,
} from "./user.service";
import {
  getCategories,
  getSasUrl,
  putVideBlocks,
  uploadStory,
  getStoryList,
} from "./video.service";

const API_SERVICES = {
  USER: {
    FORGOT_PASSWORD: forgotPassword,
    GET_ACCESS_TOKEN: getAccessToken,
    GET_MY_STORIES: getMyStories,
    GET_PROFILE_DETAILS: getProfileDetails,
    REGISTER_USER: registerUser,
    RESET_PASSWORD: resetPassword,
    UPDATE_PROFILE: updateProfile,
    VERIFY_EMAIL: verifyEmail,
    CONTACT_US: contactUs,
    Resent_VERIFICATION: resentVerification,
  },
  VIDEO: {
    GET_CATEGORIES: getCategories,
    GET_SAS_URL: getSasUrl,
    PUT_VIDEO_BLOCKS: putVideBlocks,
    UPLOAD_STORY: uploadStory,
    GET_STORY_LIST: getStoryList,
  },
};

export default API_SERVICES;
