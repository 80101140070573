enum HomeUrlActionParams {
  login = "login",
  forgotPassword = "forgotPassword",
}
export const CONSTANTS = {
  HTTP_STATUS: {
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    INTERNAL_SERVER_ERROR: 500,
    NOT_FOUND: 404,
    SUCCESS: 200,
    UNAUTHORIZED: 401,
  },

  LOCAL_STORAGE: {
    ACCESS_TOKEN: "ACCESS_TOKEN",
    PROFILE: "PROFILE",
    REFRESH_TOKEN: "REFRESH_TOKEN",
  },

  STATUS: {
    SUCCESS: "success",
    FAILED: "failed",
  },
  URL_PARAMS: {
    HOME: {
      ACTION: HomeUrlActionParams,
    },
  },
  VALIDATIONS: {
    FIELD_REQUIRED: "This field is required",
  },

  DESCRIPTION_LENGTH: 360,
};
