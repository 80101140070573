import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faEdit } from "@fortawesome/free-solid-svg-icons";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";

import {
  MyProfileUpdateProps,
  profileFormData,
} from "../../../model/profile.model";
import FormInput from "../../Contact/FormInput";
import {
  formInputController,
  selectOptions,
} from "../../../model/contact.formInput.model";

const MyProfileUpdate: FunctionComponent<MyProfileUpdateProps> = (
  props: MyProfileUpdateProps
) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [formData, setFormData] = useState<profileFormData | any>(null);
  const [formErrors, setFormErrors] = useState<Record<string, string>>({});

  useEffect(() => {
    setFormData(props.formData);
  }, [props.formData]);
  const handleChange = (value: string | selectOptions | any, itemData: any) => {
    const data: profileFormData | any = { ...formData };
    let val: string = value;

    if (typeof val === "string") {
      val = val.trim();
    }

    if (itemData?.type === "select") {
      val = value.value;
    }

    data[itemData.id] = val;
    setFormData(data);
    setFormErrors((errors) => ({
      ...errors,
      [itemData.id]: itemData?.required
        ? val
          ? ""
          : "This field is required"
        : "",
    }));
  };
  const renderFormInput = (item: formInputController) => {
    let inputProps: any = {};

    if (item?.props) inputProps = item?.props;

    if (item?.type === "select") return renderSelect(item);

    const val: any = (item?.id && formData?.[item?.id || ""]) || "";
    const invalid = (item?.id && formErrors[item?.id]) || "";

    return (
      <FormInput
        formData={{
          id: item.id,
          label: item.label,
          placeholder: item.placeholder,
          value: val,
          required: item.required,
          invalid: invalid,
        }}
        actions={{ handleChange: handleChange }}
        inputProps={inputProps}
      />
    );
  };

  const renderSelect = (item: formInputController) => {
    const val: any = formData !== null ? formData?.[item?.id || ""] : null;

    const selectedOption: any = item?.options?.find(
      (opt: selectOptions) => opt.value === val
    );

    const invalid = (item?.id && formErrors[item?.id]) || "";

    return (
      <div className="form-floating mb-3">
        <label
          htmlFor="category"
          className={`st-label ${
            selectedOption === null ? "" : " st-select-label"
          }`}
        >
          {item.placeholder}{" "}
          {item?.required && <span style={{ color: "red" }}>*</span>}
        </label>
        <Select
          value={selectedOption}
          onChange={(selectedOption: any) =>
            handleChange(selectedOption, {
              ...{ id: item?.id || "", type: "select" },
              ...formData,
            })
          }
          options={item.options}
          className="st-select"
          classNamePrefix="st-select"
          classNames={{
            indicatorSeparator: (state: any) =>
              state.isFocused ? "d-none" : "d-none",
            dropdownIndicator: (state: any) =>
              state.isFocused ? "st-btn-menu-caret" : "st-btn-menu-caret",
          }}
          placeholder={""}
        />
        {invalid && <div className="invalid-tooltip d-block">{invalid}</div>}
      </div>
    );
  };

  const showFileDialog = () =>
    inputFileRef.current && inputFileRef.current.click();

  const submit = (e: React.FormEvent) => {
    e.preventDefault();

    const required_error = "This field is required";
    const errors: Record<string, string> = {};

    const trimmedFormData: profileFormData = {
      ...formData,
      first_name: formData?.first_name.trim(),
      last_name: formData?.last_name.trim(),
      city: formData?.city.trim(),
    };

    if (!trimmedFormData.first_name) errors.first_name = required_error;
    if (!trimmedFormData.last_name) errors.last_name = required_error;
    if (!trimmedFormData.city) errors.city = required_error;

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      props.onSave(trimmedFormData);
    }
  };

  const uploadFile = (e: any) => {
    props.handleUpload(e.target.files[0]);
  };

  const formItems: formInputController[] = [
    {
      id: "email",
      label: "Email",
      placeholder: "Email",
      value: formData?.["email"] || "",
      props: {
        disabled: true,
      },
    },

    {
      id: "display_name",
      label: "Display name",
      placeholder: "Display name",
      value: formData?.["display_name"] || "",
    },
    {
      id: "first_name",
      label: "First name",
      placeholder: "First name",
      required: true,
      value: formData?.["first_name"] || "",
    },
    {
      id: "last_name",
      label: "Last name",
      placeholder: "Last name",
      required: true,
      value: formData?.["last_name"] || "",
    },
    {
      id: "gender",
      label: "Select gender",
      placeholder: "Select gender",
      type: "select",
      value: formData?.["gender"] || "",
      options: [
        {
          label: "Male",
          value: "Male",
        },
        {
          label: "Female",
          value: "Female",
        },
        {
          label: "Not binary",
          value: "Not binary",
        },
        {
          label: "Rather not say",
          value: "Rather not say",
        },
      ],
    },
    {
      id: "city",
      label: "Select city",
      placeholder: "Select city",
      required: true,
      value: formData?.["city"] || "",
    },
    {
      id: "country",
      label: "Select country",
      placeholder: "Select country",
      type: "select",
      value: formData?.["country"] || "",
      options: [
        {
          label: "United states of America",
          value: "USA",
        },
        {
          label: "Canada",
          value: "CA",
        },
      ],
    },
    {
      id: "age_range",
      label: "Select age range",
      placeholder: "Select age range",
      type: "select",
      value: formData?.["age_range"] || "",
      options: [
        {
          label: "Less than 18",
          value: "<18",
        },
        {
          label: "18-34",
          value: "18-34",
        },
        {
          label: "35-54",
          value: "35-54",
        },
        {
          label: "55-70",
          value: "55-70",
        },
        {
          label: "Above 70",
          value: ">70",
        },
      ],
    },
  ];

  return (
    <div className="my-profile my-5">
      <div className="container">
        <div className="flex-header text-start">
          <h5>My profile</h5>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div
              className="profile-bubble st-cursor-pointer"
              onClick={() => showFileDialog()}
            >
              <div className="st-avatar-wrapper">
                <input
                  style={{ display: "none" }}
                  ref={inputFileRef}
                  type="file"
                  multiple={true}
                  onChange={(e) => uploadFile(e)}
                  accept="image/png, image/gif, image/jpeg"
                />
                {formData?.image ? (
                  <>
                    <button className="st-profile-edit-btn">
                      <FontAwesomeIcon
                        icon={faEdit}
                        className="st-text-red"
                        size="xl"
                      />
                    </button>
                    <img
                      src={formData?.image}
                      alt={formData?.first_name}
                      className="st-avatar rounded-circle"
                    />
                  </>
                ) : (
                  <>
                    <button className="st-profile-edit-btn">
                      <FontAwesomeIcon
                        icon={faCamera}
                        className="st-text-red"
                        size="xl"
                      />
                    </button>
                    <div className="st-placeholder rounded-circle"></div>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="my-profile-form">
              <form id="my-profile-form" onSubmit={submit}>
                {formItems.map(
                  (formItem: formInputController, index: number) => (
                    <React.Fragment key={index}>
                      {renderFormInput(formItem)}
                    </React.Fragment>
                  )
                )}
                <div className="text-end mt-3">
                  <button className="st-btn st-btn-primary" type="submit">
                    Update profile
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfileUpdate;
