import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
interface ConfirmModalProps {
  title?: string;
  description?: string;
  confirmbuttontext?: string;
  cancelbuttontext?: string;
  onConfirm: Function;
  onCancel: Function;
  isShow: boolean;
}
const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title = "Are you sure want to delete this item",
  description,
  confirmbuttontext = "Confirm",
  cancelbuttontext = "Cancel",
  onConfirm,
  onCancel,
  isShow,
}) => {
  return (
    <div>
      <Modal isOpen={isShow}>
        <ModalBody>
          <div className="app-row-padding">
            <div style={{ padding: 10, textAlign: "center" }}>
              <h6 className="st-confirm-title">{title}</h6>
            </div>
            <div style={{ padding: 0, textAlign: "center" }}>
              <Button
                className="st-confirm-btn"
                color="secondary"
                style={{ margin: 5 }}
                onClick={() => onConfirm()}
              >
                {" "}
                {confirmbuttontext}
              </Button>

              <Button
                className="st-cancel-btn"
                color="secondary"
                style={{ margin: 5 }}
                onClick={() => onCancel()}
              >
                {cancelbuttontext}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ConfirmModal;
