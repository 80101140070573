import {
  FunctionComponent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import { Spinner } from "reactstrap";

import Layout from "../../layouts/Layout";
import PageBanner from "../../components/shared/PageBanner/PageBanner";
import PromoBanner from "../../components/shared/PromoBanner/PromoBanner";
import API_SERVICES from "../../services";
import { useSearchParams, useNavigate } from "react-router-dom";
import { handleResponse } from "../../services/apiConfig/api.response.service";
import commonService from "../../services/common.service";
import { CONSTANTS } from "../../constants";
import { UserContext } from "../../context/userContext";
import FormInput from "../../components/Contact/FormInput";

const VerifyAccountPage: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const isVerifying = useRef(false);
  const { user, login }: any = useContext(UserContext);
  const [showResendVerification, setShowResendVerification] = useState(false);
  const [error, setError] = useState("");
  const [emailInputValue, setEmailInputValue] = useState("");

  useEffect(() => {
    if (!isVerifying.current) verifyAccount();
  }, []);

  const getProfileDetails = () => {
    API_SERVICES.USER.GET_PROFILE_DETAILS().then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        commonService.toastService("Email verified successfully", "success");
        login(responseData.data);
      }
    });
  };
  let navigate = useNavigate();
  const verifyAccount = () => {
    isVerifying.current = true;
    const accessToken: string = searchParams.get("token") || "";
    API_SERVICES.USER.VERIFY_EMAIL(accessToken)
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData?.ok) {
          const tokens: any = {};
          tokens[CONSTANTS.LOCAL_STORAGE.ACCESS_TOKEN] =
            responseData.data.token.access;
          tokens[CONSTANTS.LOCAL_STORAGE.REFRESH_TOKEN] =
            responseData.data.token.refresh;
          commonService.setLocalStorage(tokens);
          getProfileDetails();
        } else {
          commonService.toastService("Email verification failed", "error");
          setShowResendVerification(true);
        }
        isVerifying.current = false;
      })
      .catch((e) => {
        isVerifying.current = false;
      });
  };
  if (user?.first_name) {
    navigate("/dashboard/profile");
  }
  const resendVerificationMail = () => {
    if (!emailInputValue) {
      if (!error) setError("this value is required");
      return;
    }
    API_SERVICES.USER.Resent_VERIFICATION({ email: emailInputValue })
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData?.ok) {
          commonService.toastService(
            "Verification mail sent successfully. Please check your mail."
          );
        } else {
          throw new Error("Sending verification mail failed.");
        }
      })
      .catch((e) => {
        commonService.toastService(e.message, "error");
      });
  };

  return (
    <div>
      <Helmet>
        <title>Story talk | FAQ</title>
      </Helmet>
      <Layout>
        <PageBanner
          image="/assets/images/banner1.png"
          containerClass="st-page-banner-content black"
        >
          <div className="container">
            {!isVerifying.current && showResendVerification ? (
              <div
                style={{
                  width: "50%",
                  maxWidth: "1000px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                  marginInline: "auto",
                }}
              >
                <h3 style={{ color: "white" }}>
                  Oops, Looks like the verification link you are using is no
                  longer valid. If you want to continue, please fill in your
                  registered email and we will send you a new verification link.
                </h3>
                <FormInput
                  formData={{
                    id: "email",
                    label: "Registered Email",
                    placeholder: "Registered Email",
                    value: emailInputValue,
                    type: "email",
                    invalid: error,
                  }}
                  actions={{
                    handleChange: (value: string) => {
                      setEmailInputValue(value);
                      if (error.length && value.length) setError("");
                      if (!error.length && !value.length)
                        setError("this value is required");
                    },
                  }}
                  containerClass={"st-text-sand"}
                />
                <button
                  type="submit"
                  className="st-btn st-btn-primary"
                  style={{ margin: "auto" }}
                  onClick={() => resendVerificationMail()}
                >
                  Submit
                </button>
              </div>
            ) : (
              <div className="uploading text-start">
                <h1 className="text-start">
                  Verifying your account please wait
                  <span className="mx-3">
                    <Spinner color="danger"> </Spinner>
                  </span>{" "}
                </h1>
              </div>
            )}
          </div>
        </PageBanner>
        <PromoBanner />
      </Layout>
    </div>
  );
};

export default VerifyAccountPage;
