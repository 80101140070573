import { FunctionComponent } from "react";
import { WelcomeCardProps } from "../../../model/home.model";

const WelcomeCard: FunctionComponent<WelcomeCardProps> = () => {
  return (
    <div className="st-home-welcome-card d-none d-md-block">
      <div className="container my-5">
        <h1 className="st-title">
          Welcome to <span className="st-text-red">Story</span>Talk
        </h1>
        <h4 className="st-subtitle">
          The place to share the meaningful human moments that make us all,
          well... human.
        </h4>
      </div>
    </div>
  );
};

export default WelcomeCard;
