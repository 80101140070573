import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import Header from "../components/shared/header";
import Footer from "../components/shared/footer";
import { useAuth } from "../context/userContext";

function LayoutProtected({ children }: any) {
  const { user, login, logout }: any = useAuth();

  useEffect(() => {}, []);

  if (!user) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <Header onSignInClick={() => {}} />
      {children}
      <Outlet />
      <Footer />
    </>
  );
}

export default LayoutProtected;
