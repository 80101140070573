import { FunctionComponent } from "react";
import { DividerProps } from "../../../model/shared.model";

const Divider: FunctionComponent<DividerProps> = (props: DividerProps) => {
  return (
    <div
      className={`st-divider ${props?.className && props.className} ${
        props?.text && "has-text"
      }`}
    >
      {props?.text && <div className="st-text">{props.text}</div>}
    </div>
  );
};

export default Divider;
