import { FunctionComponent, useEffect } from "react";
import Layout from "../../layouts/Layout";
import PromoBanner from "../../components/shared/PromoBanner/PromoBanner";
import PageBanner from "../../components/shared/PageBanner/PageBanner";
import { Helmet } from "react-helmet";

const NotFoundPage: FunctionComponent = () => {
  useEffect(() => {}, []);
  return (
    <>
      <Helmet>
        <title>Story talk | 404 : Not found</title>
      </Helmet>
      <Layout>
        <PageBanner
          image="/assets/images/banner1.png"
          containerClass="st-page-banner-content"
        >
          <div className="container">
            <div className="uploading text-start">
              <h1 className="text-start">404 - Page not found</h1>
            </div>
          </div>
          {/* <div className="upload-video-details">
            <h1>Page not found</h1>
          </div> */}
        </PageBanner>
        <div className="mission">
          <div className="container">
            <div className="mission_text">
              <h3>404 : Page not found</h3>
            </div>
            <p>
              Sorry, we can't find that page. You'll find lots to explore on the
              home page.
            </p>
          </div>
        </div>
        <PromoBanner />
      </Layout>
    </>
  );
};

export default NotFoundPage;
