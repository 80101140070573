import { FunctionComponent, useEffect, useRef, useState } from "react";
import { usePrevious } from "../../../hooks/usePrevious";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { useAuth } from "../../../context/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import {
  CategoryItem,
  CategoryNavbarProps,
} from "../../../model/village.model";

const CategoryNavbar: FunctionComponent<CategoryNavbarProps> = ({
  isOpen,
  categories,
  toggleNav,
  handleRedirection,
}) => {
  return (
    <>
      <aside className={`st-resp-navbar st-cat-nav ${isOpen && "open"}`}>
        <header className="st-header  ">
          <h5>Story Categories</h5>
          <button
            type="button"
            className="st-close-btn"
            aria-label="Close"
            onClick={() => toggleNav()}
          >
            <button>
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="st-text-red"
                size="2xl"
              />
            </button>
          </button>
        </header>
        <div className="resp-nav ">
          <div className="p-4 d-flex w-100">
            <Nav className="m-0 d-flex  flex-column st-nav text-start ">
              {categories?.map((cat: any, index: number) => (
                <NavItem key={index}>
                  {handleRedirection ? (
                    <div
                      className="st-cursor-pointer text-white"
                      onClick={() => handleRedirection(cat?.id, cat?.name)}
                    >
                      {cat?.name}
                    </div>
                  ) : (
                    <NavLink
                      to={`/village/category/${cat.categoryId}/${cat.title}`}
                    >
                      {cat.title}
                    </NavLink>
                  )}
                </NavItem>
              ))}
            </Nav>
          </div>
        </div>
      </aside>
    </>
  );
};

export default CategoryNavbar;
