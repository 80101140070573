import { FunctionComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faColumns, faList } from "@fortawesome/free-solid-svg-icons";

interface MyStoriesHeaderProps {
  toggle: Function;
  isGridView: boolean;
}

const MyStoriesHeader: FunctionComponent<MyStoriesHeaderProps> = ({
  toggle,
  isGridView,
}) => {
  return (
    <>
      <div className="container">
        <div className="flex-header px-2 text-start">
          <h5>My stories</h5>
          <div className="st-cursor-pointer" onClick={() => toggle()}>
            {isGridView ? (
              <FontAwesomeIcon
                size={"xl"}
                className="st-text-sand mx-2"
                icon={faList}
              />
            ) : (
              <FontAwesomeIcon
                size={"xl"}
                className="st-text-sand "
                icon={faColumns}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyStoriesHeader;
