import { FunctionComponent, useEffect, useRef, useState } from "react";
import LayoutProtected from "../../layouts/LayoutProtected";
import { Helmet } from "react-helmet";
import PageBanner from "../../components/shared/PageBanner/PageBanner";
import MyStories from "../../components/Profile/MyStories/MyStories";
import API_SERVICES from "../../services";
import VideoPreviewModal from "../../components/shared/VideoPreviewModal/VideoPreviewModal";
import { storiesItem } from "../../model/shared.model";
import { useNavigate } from "react-router-dom";
import MyProfileUpdate from "../../components/Profile/MyProfileUpdate/MyProfileUpdate";
import { handleResponse } from "../../services/apiConfig/api.response.service";
import { CONSTANTS } from "../../constants";
import commonService from "../../services/common.service";
import { profileFormData } from "../../model/profile.model";
import PageLoader from "../../components/shared/PageLoader/PageLoader";
import BetterStoryTellerCard from "../../components/shared/BetterStoryTellerCard/BetterStoryTellerCard";
import { Alert } from "reactstrap";
import MyStoriesHeader from "../../components/Profile/MyStories/MyStoriesHeader";
import MyStoriesListView from "../../components/Profile/MyStories/MyStoriesListView";

const Profile: FunctionComponent = () => {
  const [myStories, setMyStories] = useState<any>([]);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const [currentVideo, setCurrentVideo] = useState<storiesItem | null>(null);
  const [profileFormData, setProfileFormData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isGridView, setGridView] = useState(true);
  let navigate = useNavigate();
  const fetchData: any = useRef({
    storyPage: 0,
    storyTotalCount: 0,
    storyNextPageUrl: 0,
  });
  const apiStatus = useRef({
    completed: 0,
    total: 0,
  });

  useEffect(() => {
    apiStatus.current = {
      completed: 0,
      total: 2,
    };
    setIsFetching(true);
    loadMyStories();
    loadProfile();
  }, []);

  const handlePlay = (video: storiesItem) => {
    setCurrentVideo(video);
    setPreviewModalOpen(true);
  };

  const handleProfileSave = (formData: profileFormData) => {
    setIsFetching(true);
    const payload: any = { ...formData };
    delete payload["image"];
    delete payload["email"];
    API_SERVICES.USER.UPDATE_PROFILE(payload).then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        updateProfileLocalStorage(responseData.data);
        setIsFetching(false);
        commonService.toastService("Update Successful", "success");
      } else {
        commonService.toastService("Update Failed", "error");
      }
    });
  };

  const handleAvatarUpdate = (file: any) => {
    setIsFetching(true);
    const apiHeader: any = commonService.getApiHeader();
    let form_data: any = new FormData();
    form_data.append("pic", file, file.name);
    form_data.append("title", "title");
    form_data.append("body", "body");
    apiHeader["Content-Type"] = "multipart/form-data";
    apiHeader["X-CSRFTOKEN"] = "CSRF_TOKEN";
    API_SERVICES.USER.UPDATE_PROFILE({ image: file }, apiHeader).then(
      (data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData?.ok) {
          updateProfileLocalStorage(responseData.data);
          setIsFetching(false);
          commonService.toastService("Update Successful", "success");
        } else {
          commonService.toastService("Update Failed", "error");
        }
      }
    );
  };
  const loadMyStoriesMore = () => {
    if (fetchData.current.storyNextPageUrl) {
      setIsFetching(true);
      loadMyStories(fetchData.current.storyPage);
    }
  };
  const loadMyStories = (pageNo?: number) => {
    const data: any = {
      category: 0,
      featured_carousel: false,
      is_featured: false,
    };

    API_SERVICES.USER.GET_MY_STORIES(data, pageNo).then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData.ok) {
        const currentStories: any = { ...myStories };
        const newStories: Array<storiesItem> = responseData.data.results;

        if (responseData.data.next !== null) {
          const page: string = responseData.data.next.split("=")[1];
          fetchData.current.storyPage = page;
        }
        fetchData.current.storyTotalCount = responseData.data.count;
        fetchData.current.storyNextPageUrl = responseData.data.next;
        apiStatus.current.completed = apiStatus.current.completed + 1;
        if (currentStories?.results) {
          currentStories.results.push(...newStories);
          setMyStories(currentStories);
        } else {
          setMyStories(responseData.data);
        }
      }
      stopLoader();
    });
  };

  const loadProfile = () => {
    const profileData = commonService.getLocalStorage(
      CONSTANTS.LOCAL_STORAGE.PROFILE,
      "JSON"
    );
    setProfileFormData(profileData);
    API_SERVICES.USER.GET_PROFILE_DETAILS().then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        apiStatus.current.completed = apiStatus.current.completed + 1;
        stopLoader();
        updateProfileLocalStorage(responseData.data);
      }
    });
  };

  const noStoryNotice: any = () => {
    return (
      <Alert color="warning text-start">
        No stories added yet, Please{" "}
        <button
          className="st-btn st-btn-primary"
          onClick={() => {
            navigate("/dashboard/new-story");
          }}
        >
          Tell a new story
        </button>
      </Alert>
    );
  };

  const stopLoader = () => {
    if (apiStatus.current.completed >= apiStatus.current.total) {
      setIsFetching(false);
    } else if (!isFetching) setIsFetching(true);
  };

  const toggleDisplayType = () => {
    setGridView(!isGridView);
  };

  const updateProfileLocalStorage = (responseData: any) => {
    const data: any = {};
    data[CONSTANTS.LOCAL_STORAGE.PROFILE] = responseData;
    commonService.setLocalStorage(data, "JSON");
    setProfileFormData(data[CONSTANTS.LOCAL_STORAGE.PROFILE]);
  };
  return (
    <>
      <Helmet>
        <title>Story talk | Profile</title>
      </Helmet>
      <PageLoader isLoading={isFetching} />
      <VideoPreviewModal
        video={currentVideo}
        isOpen={isPreviewModalOpen}
        closeModal={() => setPreviewModalOpen(false)}
      />
      <PageBanner
        image="/assets/images/my-profile-banner-1.png"
        containerClass="st-page-banner-content black"
      >
        <div className="container">
          <div className="uploading text-start">
            <h1 className="text-start">My Profile</h1>

            <div className="my-story-btns d-flex">
              <button
                className="st-btn st-btn-primary"
                onClick={() => {
                  navigate("/dashboard/new-story");
                }}
              >
                Tell a new story
              </button>
            </div>
          </div>
        </div>
      </PageBanner>
      <div className="st-bg-coffee st-profile-stories">
        <MyStoriesHeader toggle={toggleDisplayType} isGridView={isGridView} />
        {isGridView ? (
          <MyStories
            stories={myStories}
            onPlay={handlePlay}
            isFetching={isFetching}
            title=""
            containerClass="st-bg-coffee pb-5"
            noContentAlert={noStoryNotice}
          />
        ) : (
          <MyStoriesListView
            stories={myStories}
            onPlay={handlePlay}
            isFetching={isFetching}
            title=""
            containerClass="st-bg-coffee pb-5"
            noContentAlert={noStoryNotice}
          />
        )}
        {fetchData.current.storyNextPageUrl && (
          <div className="text-center py-4">
            <button
              type="button"
              className="st-btn st-btn-primary"
              style={{ margin: 0 }}
              onClick={() => loadMyStoriesMore()}
            >
              Load More
            </button>
          </div>
        )}
      </div>
      <MyProfileUpdate
        handleUpload={handleAvatarUpdate}
        formData={profileFormData}
        onSave={handleProfileSave}
      />
      <BetterStoryTellerCard onPlay={handlePlay} />
    </>
  );
};

export default Profile;
