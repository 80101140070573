import React, { useState } from "react";
import { SignInModalProps } from "../../../model/user.model";
import { FormFeedback, Input, Modal } from "reactstrap";
import commonService from "../../../services/common.service";
import { formInputController } from "../../../model/contact.formInput.model";
import FormInput from "../../Contact/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
function SignUpModal(props: SignInModalProps) {
  const { handleSignIn, isModalOpen, toggleModal, toggleModalType } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isFirstNameInvalid, setIsFirstNameInvalid] = useState("");
  const [isLastNameInvalid, setIsLastNameInvalid] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState("");
  const [isPasswordInvalid, setPasswordInvalid] = useState("");
  const [isCPasswordInvalid, setCPasswordInvalid] = useState("");

  const handleSignUp = (e: any) => {
    e.preventDefault();

    signUpInputs.forEach((input) => {
      handleChange(input.value, input);
    });

    if (
      isFirstNameInvalid ||
      isLastNameInvalid ||
      isEmailInvalid ||
      isPasswordInvalid ||
      isCPasswordInvalid
    )
      return;

    if (firstName && lastName && email && password && confirmPassword) {
      if (password === confirmPassword) {
        handleSignIn({
          display_name: firstName,
          first_name: firstName,
          last_name: lastName,
          email,
          password,
        });
      } else {
        console.log("Passwords do not match");
      }
    } else {
      console.log("All fields are required");
    }
  };

  const handleChange = (value: string, itemData: formInputController) => {
    switch (itemData.id) {
      case "firstName":
        setFirstName(value);
        if (!value && !isFirstNameInvalid)
          setIsFirstNameInvalid("This field is required");
        if (value && isFirstNameInvalid) setIsFirstNameInvalid("");
        break;
      case "lastName":
        setLastName(value);
        if (!value && !isLastNameInvalid)
          setIsLastNameInvalid("This field is required");
        if (value && isLastNameInvalid) setIsLastNameInvalid("");
        break;
      case "email":
        setEmail(value);
        if (!value && !isEmailInvalid)
          setIsEmailInvalid("This field is required");
        if (value && isEmailInvalid) setIsEmailInvalid("");
        break;
      case "password":
        setPassword(value);
        if (!value && !isPasswordInvalid)
          setPasswordInvalid("This field is required");
        if (value && isPasswordInvalid) setPasswordInvalid("");
        handlePasswordValidation(value, confirmPassword);
        break;
      case "confirmPassword":
        setConfirmPassword(value);
        if (!value && !isCPasswordInvalid)
          setCPasswordInvalid("This field is required");
        if (value && isCPasswordInvalid) setCPasswordInvalid("");
        handlePasswordValidation(value, confirmPassword);
        handlePasswordValidation(password, value);
        break;
    }
  };

  const handlePasswordValidation = (pswd: string, cnfPswd: string) => {
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,26}$/;
    if (!re.test(pswd)) {
      setPasswordInvalid(
        "Your password must be at least 8 characters including a special character and a number"
      );
    } else if (pswd.length < 8)
      setPasswordInvalid("Password length minimum 8.");
    else if (cnfPswd !== "" && cnfPswd !== pswd) {
      setPasswordInvalid("");
      setCPasswordInvalid("Password not matching");
    } else {
      setPasswordInvalid("");
      setCPasswordInvalid("");
    }
  };

  const signUpInputs: formInputController[] = [
    {
      id: "firstName",
      label: "First name",
      placeholder: "First Name",
      value: firstName,
      invalid: isFirstNameInvalid,
      required: true,
    },
    {
      id: "lastName",
      label: "Last name",
      placeholder: "Last Name",
      value: lastName,
      invalid: isLastNameInvalid,
      required: true,
    },
    {
      id: "email",
      label: "Email address",
      placeholder: "Email address",
      value: email,
      type: "email",
      invalid: isEmailInvalid,
      required: true,
    },

    {
      id: "password",
      type: "password",
      label: "Password",
      placeholder: "Password",
      value: password,
      invalid: isPasswordInvalid,
      required: true,
    },
    {
      id: "confirmPassword",
      type: "password",
      label: "Confirm password",
      placeholder: "Confirm Password",
      value: confirmPassword,
      invalid: isCPasswordInvalid,
      required: true,
    },
  ];
  return (
    <>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        className="st-custom-modal"
        size="lg"
        centered
      >
        <div className="modal-content">
          <div className="modal-header mt-3">
            <div className="form-head">
              <img src="/assets/images/storytalk-village-logo.svg" alt="" />
              <h2>Sign Up</h2>
            </div>
            <button
              type="button"
              className="st-close-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={toggleModal}
            >
              <button>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="st-text-red"
                  size="2xl"
                />
              </button>
            </button>
          </div>
          <div className="modal-body">
            <form className="st-sign-in-form" onSubmit={handleSignUp}>
              {signUpInputs.map((formData: any, index: number) => (
                <FormInput
                  key={index}
                  formData={formData}
                  actions={{ handleChange: handleChange }}
                  containerClass={"st-text-sand"}
                />
              ))}

              <div className="modal-footer flex-column">
                <div
                  className="st-cursor-pointer st-text-red text-end my-4 align-self-end"
                  onClick={() => toggleModalType("signin")}
                >
                  Go to Sign In
                </div>
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="st-btn st-btn-primary"
                    style={{ margin: 0 }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SignUpModal;
