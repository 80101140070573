import { FunctionComponent, useEffect, useRef, useState } from "react";
import ReactHlsPlayer from "react-hls-player/dist";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { VideoPreviewModalProps, iVideo } from "../../../model/shared.model";
import CategoryPills from "../../Village/CategoryPills/CategoryPills";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { CONSTANTS } from "../../../constants";
const VideoPreviewModal: FunctionComponent<VideoPreviewModalProps> = (
  props: VideoPreviewModalProps
) => {
  const playerRef = useRef<any>();
  const { isOpen, closeModal, video } = props;

  const [isExpand, setExpand] = useState(false);

  const getVideoDesc = () => {
    let description: string = video?.description || "";
    if (description?.length > CONSTANTS.DESCRIPTION_LENGTH && !isExpand) {
      description =
        description.slice(0, CONSTANTS.DESCRIPTION_LENGTH - 20) + "...";
    }

    return description;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      centered
      className="st-custom-modal st-video-preview-modal"
      size="xl"
    >
      <ModalBody className="pt-4 pt-lg-3 pb-0">
        <button
          type="button"
          className="st-close-btn"
          aria-label="Close"
          onClick={closeModal}
        >
          <button>
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="st-text-red"
              size="2xl"
            />
          </button>
        </button>
        {video && (
          <ReactHlsPlayer
            playerRef={playerRef}
            src={video.media_url}
            poster={video.thumbnail}
            width={"100%"}
            height={"100%"}
            autoPlay={true}
            controls={true}
            playsInline={true}
            webkit-playsinline={true}
            disablePictureInPicture
          />
        )}
      </ModalBody>
      <ModalFooter className="d-block text-start">
        <h5 className="st-text-sand">{video?.title}</h5>
        <section className="st-meta | d-flex justify-content-between">
          <div className="">{video?.author?.first_name}</div>
          <div>
            <CategoryPills items={video?.category_data || []} />
          </div>
        </section>
        <p className="st-text-bright-grey">
          {getVideoDesc()}{" "}
          {(video?.description?.length || 0) > CONSTANTS.DESCRIPTION_LENGTH && (
            <button
              className="st-text-red px-0"
              onClick={() => setExpand(!isExpand)}
            >
              {isExpand ? (
                <>
                  Read Less
                  <FontAwesomeIcon
                    icon={faChevronCircleLeft}
                    className="st-text-red mx-2"
                  />
                </>
              ) : (
                <>
                  Read More
                  <FontAwesomeIcon
                    icon={faChevronCircleRight}
                    className="st-text-red mx-2"
                  />
                </>
              )}
            </button>
          )}
        </p>
      </ModalFooter>
    </Modal>
  );
};

export default VideoPreviewModal;
