import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { userProvideContextInitState } from "../model/user.model";
import { CONSTANTS } from "../constants";
export const UserContext: any = createContext(null);

export const AuthProvider = ({ children }: any) => {
  const [user, setUser] = useLocalStorage(
    CONSTANTS.LOCAL_STORAGE.PROFILE,
    null
  );
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data: any, redirect = true) => {
    setUser(data);
    redirect && navigate("/dashboard/profile");
  };

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null);
    localStorage.clear();
    navigate("/", { replace: true });
  };

  const value: any = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useAuth = () => {
  return useContext(UserContext);
};
