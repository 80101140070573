import { CONSTANTS } from "../../constants";
import commonService from "../common.service";
import { createBrowserHistory } from "history";

function handleResponse(res: any) {
  const router: any = createBrowserHistory();
  if (!res.ok) {
    if (
      res.status === CONSTANTS.HTTP_STATUS.BAD_REQUEST ||
      res.status === CONSTANTS.HTTP_STATUS.FORBIDDEN ||
      res.status === CONSTANTS.HTTP_STATUS.NOT_FOUND ||
      res.status === CONSTANTS.HTTP_STATUS.INTERNAL_SERVER_ERROR
    ) {
      commonService.toastService(
        res?.data ? commonService.flattenObjectToString(res?.data) : "error",
        "error"
      );
    } else if (res.status === CONSTANTS.HTTP_STATUS.UNAUTHORIZED) {
      localStorage.clear();
      commonService.toastService(
        res?.data ? commonService.flattenObjectToString(res?.data) : "error",
        "error"
      );
      setTimeout(() => {
        router.push("/home/" + CONSTANTS.URL_PARAMS.HOME.ACTION.login);
      }, 2000);
    } else if (res.status === CONSTANTS.HTTP_STATUS.FORBIDDEN) {
      commonService.toastService(
        res?.data ? commonService.flattenObjectToString(res?.data) : "error",
        "error"
      );
      setTimeout(() => {
        router.push("/home/");
      }, 2000);
    }
  }

  return res;
}

export { handleResponse };
