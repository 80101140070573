import React, { useContext, useRef } from "react";
import { FileUploadProps } from "../../../types/newStory";

function FileUpload(props: FileUploadProps) {
  const fileDetailsRef: any = useRef<any>({});

  const { handleFileSelect } = props;

  const inputFileRef = useRef<HTMLInputElement>(null);

  const uploadFiles = (e: any) => {
    const selectedFile: File = e.target.files[0];
    const fileDetails: any = {
      name: selectedFile.name,
      size: selectedFile.size,
      type: selectedFile.type,
    };
    fileDetailsRef.current = fileDetails;
    handleFileSelect(e, fileDetails);
  };

  const showFileDialog = () =>
    inputFileRef.current && inputFileRef.current.click();

  return (
    <>
      <div className="input-file ">
        <input
          style={{ display: "none" }}
          ref={inputFileRef}
          type="file"
          multiple={true}
          onChange={(e) => uploadFiles(e)}
          accept="video/mp4,video/x-m4v,video/*"
        />
        <button
          className="st-btn st-btn-primary mb-3 mb-lg-0"
          onClick={() => showFileDialog()}
        >
          Upload a video
        </button>
      </div>
    </>
  );
}

export default FileUpload;
