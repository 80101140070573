import { FunctionComponent } from "react";
import { Badge } from "reactstrap";
import { CategoryItem, CategoryPillsProps } from "../../../model/village.model";

const CategoryPills: FunctionComponent<CategoryPillsProps> = (
  props: CategoryPillsProps
) => {
  const { items } = props;
  return (
    <div className="d-flex gap-2">
      {items &&
        items?.map((item: CategoryItem) => (
          <Badge color="danger" pill>
            {item.name}
          </Badge>
        ))}
    </div>
  );
};

export default CategoryPills;
