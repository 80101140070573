import React, { useEffect, useRef, useState } from "react";
import VideoCapture from "../../components/shared/VideoCapture/VideoCapture";
import FileUpload from "../../components/shared/FileUpload/FileUpload";
import { Player } from "video-react";
import API_SERVICES from "../../services";
import { handleResponse } from "../../services/apiConfig/api.response.service";
import commonService from "../../services/common.service";
import axios from "axios";
import uuid from "react-uuid";
import { chunkFileDetailsInitValue } from "../../model/newStory.model";

const NewStory = () => {
  const [videoSrc, seVideoSrc] = useState("");

  const chunkFileDetails = useRef<any>(
    commonService.cloneDeep(chunkFileDetailsInitValue)
  );
  useEffect(() => {
    console.log("=================xxx===========");
  }, []);

  const reader = new FileReader();

  reader.onloadend = (evt: any) => {
    const chunkFileDtls: any = chunkFileDetails.current;
    if (evt.target.readyState == FileReader.DONE) {
      var uri =
        chunkFileDtls.submitUriData.sas_url +
        "&comp=block&blockid=" +
        chunkFileDtls.blockIds[chunkFileDtls.blockIds.length - 1];
      var requestData = new Uint8Array(evt.target.result);

      const headers = {
        "x-ms-blob-type": "BlockBlob",
        "Content-Length": requestData.length,
        "Content-Type": chunkFileDtls.fileDetails.type,
      };

      axios.put(uri, requestData, { headers }).then((response) => {
        chunkFileDtls["bytesUploaded"] += requestData.length;

        chunkFileDtls.uploadStatus = (
          (chunkFileDtls["bytesUploaded"] / chunkFileDtls.fileDetails.size) *
          100
        ).toFixed(2);

        chunkFileDetails.current = chunkFileDtls;
        uploadFileInBlocks();
      });
    }
  };

  const clearChunkData = () => {
    chunkFileDetails.current = {};
    chunkFileDetails.current = commonService.cloneDeep(
      chunkFileDetailsInitValue
    );
  };

  const commitBlockList = () => {
    let { blockIds, submitUriData }: any = chunkFileDetails.current;
    var uri = submitUriData.sas_url + "&comp=blocklist";
    var requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
    for (var i = 0; i < blockIds.length; i++) {
      requestBody += "<Latest>" + blockIds[i] + "</Latest>";
    }
    requestBody += "</BlockList>";
    const headers = {
      "Content-Length": requestBody.length,
      "Content-Type": "text/plain; charset=utf-8",
    };
    axios.put(uri, requestBody, { headers }).then((response) => {
      uploadStory();
    });
  };

  const handleCaptureUpload = (fileDetails: any) => {
    const chunkFileDtls: any = { ...chunkFileDetails.current };
    chunkFileDtls["selectedFile"] = fileDetails;
    chunkFileDtls["selectedFile"]["name"] = uuid() + ".webm";
    chunkFileDtls["fileDetails"] = fileDetails;
    const fileSize: number = fileDetails.size;
    chunkFileDtls["totalBytesRemaining"] = fileSize;
    if (fileSize < chunkFileDtls.maxBlockSize) {
      chunkFileDtls.maxBlockSize = fileSize;
    }
    if (fileSize % chunkFileDtls.maxBlockSize == 0) {
      chunkFileDtls.numberOfBlocks = Math.floor(
        fileSize / chunkFileDtls.maxBlockSize
      );
    } else {
      chunkFileDtls.numberOfBlocks =
        Math.floor(fileSize / chunkFileDtls.maxBlockSize) + 1;
    }
    chunkFileDetails.current = chunkFileDtls;

    var url = URL.createObjectURL(fileDetails);

    seVideoSrc(url);
  };

  const handleFileSelect = (e: any, fileDetails: any) => {
    const chunkFileDtls: any = { ...chunkFileDetails.current };
    const files = e.target.files;
    chunkFileDtls["selectedFile"] = files[0];
    chunkFileDtls["fileDetails"] = fileDetails;
    const fileSize: number = fileDetails.size;
    chunkFileDtls["totalBytesRemaining"] = fileSize;
    if (fileSize < chunkFileDtls.maxBlockSize) {
      chunkFileDtls.maxBlockSize = fileSize;
      console.log("max block size = " + chunkFileDtls.maxBlockSize);
    }
    if (fileSize % chunkFileDtls.maxBlockSize == 0) {
      chunkFileDtls.numberOfBlocks = Math.floor(
        fileSize / chunkFileDtls.maxBlockSize
      );
    } else {
      chunkFileDtls.numberOfBlocks =
        Math.floor(fileSize / chunkFileDtls.maxBlockSize) + 1;
    }
    var url = URL.createObjectURL(files[0]);
    seVideoSrc(url);
    chunkFileDetails.current = chunkFileDtls;
  };

  const pad = (number: number, length: number) => {
    var str = "" + number;
    while (str.length < length) {
      str = "0" + str;
    }
    return str;
  };

  const setSasUrl = () => {
    API_SERVICES.VIDEO.GET_SAS_URL().then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        const chunkFileDtls: any = chunkFileDetails.current;
        const baseUrl: string = responseData.data.sas_url;
        const indexOfQueryStart = baseUrl.indexOf("?");
        chunkFileDtls.submitUriData = responseData.data;
        chunkFileDtls.submitUriData.sas_url =
          baseUrl.substring(0, indexOfQueryStart) +
          "/" +
          chunkFileDtls.selectedFile.name +
          baseUrl.substring(indexOfQueryStart);
        uploadFileInBlocks();
      }
    });
  };

  const uploadFile = () => {
    setSasUrl();
  };

  const uploadFileInBlocks = () => {
    const chunkFileDtls: any = chunkFileDetails.current;
    let {
      blockIdPrefix,
      blockIds,
      currentFilePointer,
      maxBlockSize,
      selectedFile,
      totalBytesRemaining,
    } = chunkFileDtls;
    if (totalBytesRemaining > 0) {
      var fileContent = selectedFile.slice(
        currentFilePointer,
        currentFilePointer + maxBlockSize
      );
      var blockId = blockIdPrefix + pad(blockIds.length, 6);
      chunkFileDtls.blockIds.push(btoa(blockId));
      reader.readAsArrayBuffer(fileContent);
      chunkFileDtls.currentFilePointer += maxBlockSize;
      chunkFileDtls.totalBytesRemaining -= maxBlockSize;
      if (chunkFileDtls.totalBytesRemaining < maxBlockSize) {
        chunkFileDtls.maxBlockSize = chunkFileDtls.totalBytesRemaining;
      }
      chunkFileDetails.current = chunkFileDtls;
    } else {
      commitBlockList();
    }
  };

  const uploadStory = () => {
    const chunkFileDtls: any = chunkFileDetails.current;
    const date = new Date();
    const data: any = {
      title: "test from react_" + date,
      description: "asdasd",
      category: ["1"],
      blob_url: chunkFileDtls.submitUriData.sas_url,
      asset: String(chunkFileDtls.submitUriData.id),
    };
    API_SERVICES.VIDEO.UPLOAD_STORY(data).then((data: any) => {
      clearChunkData();
      seVideoSrc("");
    });
  };

  return (
    <div>
      xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
      <FileUpload handleFileSelect={handleFileSelect} />
      <button onClick={uploadFile}> upload </button>
      <Player src={videoSrc} fluid={false} width={480} height={272}></Player>
      <VideoCapture handleUpload={handleCaptureUpload} />
    </div>
  );
};

export default NewStory;
