import { FunctionComponent, useMemo, useState } from "react";
import { MyStoriesProps, storiesItem } from "../../../model/shared.model";
import CarouselCard from "../../shared/CarouselCard/CarouselCard";
import "react-multi-carousel/lib/styles.css";
import { Spinner } from "reactstrap";

const MyStoriesListView: FunctionComponent<MyStoriesProps> = (
  props: MyStoriesProps
) => {
  const { onPlay, stories } = props;

  const getStoryCard = (stories: storiesItem[]) => {
    return (
      stories &&
      stories.map((item: storiesItem, index: number) => {
        return (
          <CarouselCard key={index} video={item} onPlay={onPlay} isVertical />
        );
      })
    );
  };

  const slides = useMemo(() => {
    if (stories.results) return getStoryCard(stories.results);
    else return <></>;
  }, [JSON.stringify(stories.results)]);

  return (
    <>
      <div className={props?.containerClass}>
        <div className="container st-stories-vertical-list">
          {props.title !== "" && (
            <div className="flex-header  text-start">
              <h5>{props.title}</h5>
            </div>
          )}
          {stories.results?.length ? (
            <>{slides}</>
          ) : props?.isFetching ? (
            <div className="my-4 mx-auto">
              <Spinner color="danger"> </Spinner>
            </div>
          ) : (
            <>{props?.noContentAlert && <>{props?.noContentAlert()}</>}</>
          )}
        </div>
      </div>
    </>
  );
};

export default MyStoriesListView;
