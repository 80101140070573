import { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../layouts/Layout";
import PageBanner from "../../components/shared/PageBanner/PageBanner";
import PromoBanner from "../../components/shared/PromoBanner/PromoBanner";

interface PrivacyPolicyProps {}
const policies: Array<any> = [
  {
    title: "BASIC RULES",
    list: [
      "No hate speech of any type. Site owners will moderate, remove, & ban if necessary, and our decisions are final.",
      "Try best to tell your story, not someone else’s. This is not the place to practice your fiction writing.",
      "The StoryTeller is responsible for their content. No names or other detail that may identify another real person.",
      "No politics. This is not the place to preach, argue or demean. Do that elsewhere.",
      "Be kind, be honest, and have fun.",
    ],
  },
  {
    title: "OWNERSHIP",
    list: [
      "The StoryTeller co-owns what they post on StoryTalk with StoryTalk. ",
      "The StoryTeller has the right to re-post a story told & posted here anywhere else of their choosing with the proviso that it will be watermarked with the words “Story powered by StoryTalk” ",
      "StoryTalk retains the right to continue to display the story ad Infinitum (forever). ",
      "StoryTalk has the time-limited right of first refusal to match or better any offer of exploitation of a posted story in a medium other than StoryTalk. ",
    ],
  },
  {
    title: "PRIVACY & SECURITY",
    list: [
      "The Storyteller chooses whether to make their posted story private or public. ",
      "Public means anyone who visits StoryTalk will be able to see it. Private means the StoryTeller decides who can see the story by creating a private neighborhood & inviting only those they choose. ",
      "StoryTalk will use every security means available to it to protect the privacy of its users both from an information and a posting standpoint. Storytellers accept the responsibility of not posting any information about themselves they do not want known. ",
    ],
  },
];
const PrivacyPolicy: FunctionComponent<PrivacyPolicyProps> = () => {
  const renderCards = () => {
    return policies.map((policy: any) => {
      return (
        <div className="st-policy-wrapper">
          <h3 className="st-tile">{policy.title}</h3>
          <ul className="st-items st-text-sand">
            {policy.list.map((item: any) => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
      );
    });
  };

  return (
    <>
      <Helmet>
        <title>Story talk | FAQ</title>
      </Helmet>
      <Layout>
        <PageBanner
          image="/assets/images/slideHomeBanner4.jpg"
          containerClass="st-page-banner-content  "
        >
          <div className="container">
            <div className="uploading text-start">
              <h1 className="text-start">Privacy & Disclaimer</h1>
            </div>
          </div>
        </PageBanner>
        <section className="st-bg-coffee">
          <div className="container py-4">{renderCards()}</div>
        </section>
        <PromoBanner />
      </Layout>
    </>
  );
};

export default PrivacyPolicy;
