import toast, { ToastType } from "react-hot-toast";
import { CONSTANTS } from "../constants";

const blobToFile = (theBlob: Blob, fileName: string): File => {
  var b: any = theBlob;
  b.lastModifiedDate = new Date();
  b.name = fileName;
  b.type = "video/webm"; //TODO need t update dynamically

  return <File>theBlob;
};

const cloneDeep = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

const flattenObjectToString = function (ob: any) {
  let toReturn: any = {};
  let errorString: any = "";
  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;
    if (typeof ob[i] == "object") {
      toReturn[i] = flattenObjectToString(ob[i]);
    } else {
      toReturn[i] = ob[i];
    }
  }
  for (var key in toReturn) {
    errorString =
      errorString === ""
        ? errorString + toReturn[key]
        : errorString + ", " + toReturn[key];
  }
  console.log("errorString", errorString);
  return errorString;
};

const getApiHeader = async () => {
  const accessToken: string | null = getLocalStorage(
    CONSTANTS.LOCAL_STORAGE.ACCESS_TOKEN
  );
  const head: any = {
    Accept: "*/*",
  };
  if (accessToken) {
    head["Authorization"] = "Bearer " + accessToken;
  }
  return head;
};

const getDropdownOptions = (data: Array<any>) => {
  const options: Array<any> = [];
  data?.forEach((item: any) => {
    const option: any = {
      label: item.name,
      value: item.id,
    };
    options.push(option);
  });

  return options;
};

const getLocalStorage = (key: string, type?: string) => {
  if (type && type === "JSON")
    return JSON.parse(localStorage.getItem(key) || "{}");
  return localStorage.getItem(key);
};

const setLocalStorage = (items: any, type?: string) => {
  Object.keys(items).forEach((key: any) => {
    let data: any = items[key];
    if (type && type === "JSON") data = JSON.stringify(items[key]);
    localStorage.setItem(key, data);
  });
};

const toastService = (message: any, type: ToastType = "blank") => {
  if (type === "success") {
    toast.success(message);
    return;
  }
  if (type === "error") {
    toast.error(message);
    return;
  }
  toast(message);
};

const commonService = {
  blobToFile,
  cloneDeep,
  flattenObjectToString,
  getApiHeader,
  getDropdownOptions,
  getLocalStorage,
  setLocalStorage,
  toastService,
};

export default commonService;
