export const chunkFileDetailsInitValue: any = {
  blockIdPrefix: "block-",
  blockIds: new Array(),
  bytesUploaded: 0,
  currentFilePointer: 0,
  file: {},
  file8Array: {},
  fileDetails: {
    size: 0,
    type: "",
  },
  maxBlockSize: 256 * 1024 * 5 * 5, // Upload per block with 5 MB size
  numberOfBlocks: 1,
  reader: {},
  selectedFile: [],
  submitUriData: { sas_url: "" },
  totalBytesRemaining: 0,
  uploadStatus: 0,
};
