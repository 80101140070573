import React, { useContext, useEffect, useRef, useState } from "react";
import Header from "../components/shared/header";
import Footer from "../components/shared/footer";
import SignInModal from "../components/User/SignInModal/SignInModal";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { UserContext, useAuth } from "../context/userContext";
import API_SERVICES from "../services";
import { handleResponse } from "../services/apiConfig/api.response.service";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { CONSTANTS } from "../constants";
import commonService from "../services/common.service";
import SignUpModal from "../components/User/SignUpModal/SignUpModal";
import ForgotPassword from "../components/User/ForgotPassword/ForgotPassword";
import PageLoader from "../components/shared/PageLoader/PageLoader";
import ThanksForSignUpModal from "../components/User/SignUpModal/ThanksForSignUpModal";
import { Alert } from "reactstrap";
type LayoutPropType = {
  Id?: number | undefined | null;
  children?: React.ReactNode;
  containerClass?: string;
};

function Layout({ children, containerClass }: LayoutPropType) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState<boolean>(false);
  const [isThanksModalOpen, setIsThanksModalOpen] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState<boolean>(false);
  const { login, logout }: any = useContext(UserContext);
  let navigate = useNavigate();
  let { action } = useParams();

  useEffect(() => {
    handleAction();
  }, [action]);

  useEffect(() => {
    const profileData: any =
      commonService.getLocalStorage(CONSTANTS.LOCAL_STORAGE.PROFILE, "JSON") ||
      {};

    if (
      commonService.getLocalStorage(CONSTANTS.LOCAL_STORAGE.ACCESS_TOKEN) &&
      Object.keys(profileData).length === 0
    ) {
      setIsFetching(true);
      getProfileDetails();
    }

    handleAction();
  }, []);
  useEffect(() => {
    if (action === CONSTANTS.URL_PARAMS.HOME.ACTION.login) {
      logout();
      setIsModalOpen(true);
    } else if (action === CONSTANTS.URL_PARAMS.HOME.ACTION.forgotPassword) {
      logout();
      setIsForgotPasswordModalOpen(true);
      setIsModalOpen(true);
    }
  }, [action]);
  const getProfileDetails = () => {
    API_SERVICES.USER.GET_PROFILE_DETAILS()
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData?.ok) {
          login(responseData.data);
          toggleModal();
          setIsFetching(false);
          navigate("/dashboard/profile");
        } else {
          setIsFetching(false);
        }
      })
      .catch(() => setIsFetching(false));
  };

  const handleAction = () => {
    if (action === CONSTANTS.URL_PARAMS.HOME.ACTION.login) {
      logout();
      setIsModalOpen(true);
    } else if (action === CONSTANTS.URL_PARAMS.HOME.ACTION.forgotPassword) {
      logout();
      setIsForgotPasswordModalOpen(true);
      setIsModalOpen(true);
    }
  };
  const handleForgotPassword = (formData: any) => {
    setIsFetching(true);

    API_SERVICES.USER.FORGOT_PASSWORD(formData)
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData?.ok) {
          setResetSuccess(true);
          setIsFetching(false);
        } else {
          commonService.toastService(
            commonService.flattenObjectToString(responseData.data),
            "error"
          );
          setIsFetching(false);
        }
      })
      .catch((e: any) => commonService.flattenObjectToString(e));
  };

  const handleSignIn = (formData: any) => {
    setIsFetching(true);
    API_SERVICES.USER.GET_ACCESS_TOKEN(formData)
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData?.ok) {
          const tokens: any = {};
          tokens[CONSTANTS.LOCAL_STORAGE.ACCESS_TOKEN] =
            responseData.data.access;
          tokens[CONSTANTS.LOCAL_STORAGE.REFRESH_TOKEN] =
            responseData.data.refresh;
          commonService.setLocalStorage(tokens);
          getProfileDetails();
          commonService.toastService("Login Successful", "success");
        } else {
          setIsFetching(false);
        }
      })
      .catch((e: any) => commonService.flattenObjectToString(e));
  };

  const handleSignUp = (formData: any) => {
    setIsFetching(true);
    API_SERVICES.USER.REGISTER_USER(formData)
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData?.ok) {
          commonService.toastService("Registration Successful", "success");
          setIsSignUpModalOpen(false);
          setIsThanksModalOpen(true);
        } else {
        }
        setIsFetching(false);
      })
      .catch(() => {
        commonService.toastService("Registration Failed", "error");
        setIsFetching(false);
      });
  };

  const showSignInModal = () => {
    setIsThanksModalOpen(false);
  };

  const toggleModal = () => {
    setResetSuccess(false);
    setIsModalOpen(!isModalOpen);
    setIsSignUpModalOpen(false);
    setIsThanksModalOpen(false);
    setIsForgotPasswordModalOpen(false);
  };

  const toggleModalType = (view: string) => {
    setIsSignUpModalOpen(view === "signup");
    setIsForgotPasswordModalOpen(view === "forgotpassword");
  };

  return (
    <>
      <PageLoader isLoading={isFetching} />
      <div className={`text-start ${containerClass ? containerClass : ""}`}>
        <Header onSignInClick={toggleModal} />
        {isSignUpModalOpen ? (
          <SignUpModal
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
            handleSignIn={handleSignUp}
            toggleModalType={(x: string) => toggleModalType(x)}
          />
        ) : isForgotPasswordModalOpen ? (
          <ForgotPassword
            isModalOpen={isModalOpen}
            isSuccess={resetSuccess}
            toggleModal={toggleModal}
            handleSignIn={handleForgotPassword}
            toggleModalType={(x: string) => toggleModalType(x)}
          />
        ) : isThanksModalOpen ? (
          <ThanksForSignUpModal
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
            handleSignIn={showSignInModal}
            toggleModalType={(x: string) => toggleModalType(x)}
          />
        ) : (
          <SignInModal
            handleSignIn={handleSignIn}
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
            toggleModalType={(x: string) => toggleModalType(x)}
          />
        )}

        {children}
        <Footer />
      </div>
    </>
  );
}

export default Layout;
