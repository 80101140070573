import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

function FaqBody() {
  const [open, setOpen] = useState("1");

  const toggle = (id: any) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  return (
    <div>
      <div className="faq">
        <div className="faq-header">
          <div className="container">
            <h1 className="d-flex mt-4 flex-wrap ">
              <span className="me-2">
                <span className="faq-color">F</span>requently
              </span>
              <span className="me-2">
                <span className="faq-color">A</span>sked
              </span>
              <span>
                <span className="faq-color">Q</span>uestions
              </span>
            </h1>
          </div>
        </div>
      </div>

      <div className="faq-accordian container">
        <div className="accordion accordion-flush" id="accordionExample">
          <div className="accordion-item">
            {/* @ts-ignore */}
            <Accordion open={open} toggle={toggle}>
              <AccordionItem id="1">
                <AccordionHeader targetId="1">
                  Can I tell a story about anything I want?
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  Yes, that’s the point of StoryTalk. The only proviso is that
                  it be your story, something you experienced, not someone
                  elses. It’s about truth not fiction.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem id="2">
                <AccordionHeader targetId="2">
                  What’s the length of time my story should be
                </AccordionHeader>
                <AccordionBody accordionId="2">
                  That’s up to you. Our strong advice is that brevity is a
                  positive in holding an audience but you should not leave out
                  salient points. We find most StoryTalk stories are told
                  between four and five minutes.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem id="3">
                <AccordionHeader targetId="3">
                  Is there a limit on how long my story should be
                </AccordionHeader>
                <AccordionBody accordionId="3">
                  We ask that you not exceed 5 minutes.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem id="4">
                <AccordionHeader targetId="4">
                  Can I use my desktop camera to tell a story?
                </AccordionHeader>
                <AccordionBody accordionId="4">
                  {" "}
                  Yes, you can use your desktop or laptop to record your story.
                  Sign up or sign in to open the application in My Profile. Edge
                  and Chrome browsers are both compatible.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem id="5">
                <AccordionHeader targetId="5">
                  Are there rules about using foul language?
                </AccordionHeader>
                <AccordionBody accordionId="5">
                  We encourage avoiding foul language. We believe part of
                  becoming an effective storyteller is learning the effective
                  use of words. We realize some people can’t speak a sentence
                  without a potentially offensive word. If you use such language
                  at a minimum we will place a warning label on your story.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem id="6">
                <AccordionHeader targetId="6">
                  Can I tell someone else’s story?
                </AccordionHeader>
                <AccordionBody accordionId="6">
                  Often telling your story requires including the parts other
                  people played in what you are relating. So it’s normal and
                  fine to include such details in your story. The keywords are
                  your story. This is a place for personal story telling. There
                  are other places for practicing your skills as a fiction
                  author where all of the story, even if based on facts, is
                  created by the author.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem id="7">
                <AccordionHeader targetId="7">
                  Does my story have to be true?
                </AccordionHeader>
                <AccordionBody accordionId="7">
                  Your story should be true. You should want it to be true.
                  Clearly your truth may not be someone elses, and shared
                  experiences are open to perception. You do not want to
                  purposefully lie as you are responsible for your content so
                  you do not want to libel someone. On a positive note regarding
                  the issue of perception – often multiple friends who
                  experienced something together naturally have different
                  versions of that experience. It can be fun to have each person
                  tell their tale and then compare.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem id="8">
                <AccordionHeader targetId="8">
                  Do you reject stories?
                </AccordionHeader>
                <AccordionBody accordionId="8">
                  {" "}
                  We hope to never have to do so. However it will be necessary
                  to reject stories that break our house rules and or stories
                  that are incoherent, or of questionable content. Make no
                  mistake we will act as editors of StoryTalk , monitoring for
                  things such as hate speak and other unsavory content. This is
                  not a place for political debate or content of an overt sexual
                  nature which would be considered pornographic by courts of
                  law.
                </AccordionBody>
              </AccordionItem>
              <AccordionItem id="9">
                <AccordionHeader targetId="9">
                  How do you protect my privacy?
                </AccordionHeader>
                <AccordionBody accordionId="9">
                  {" "}
                  The site is fully protected from outside influence as well as
                  any other established site you may visit or use. We do not
                  track you or sell your personal information – that is not our
                  business model. As the storyteller you chose whether you
                  wanted to publish your story as public or private on your
                  personal page. That choice is fully respected.
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FaqBody;
