import { FunctionComponent } from "react";
import { SignInModalProps } from "../../../model/user.model";
import { Alert, Modal } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
const ThanksForSignUpModal: FunctionComponent<SignInModalProps> = (
  props: SignInModalProps
) => {
  const { handleSignIn, isModalOpen, toggleModal } = props;

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      className="st-custom-modal"
      centered
    >
      <div className="modal-content text-centers">
        <div className="modal-header">
          <button
            type="button"
            className="st-close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={toggleModal}
          >
            <button>
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="st-text-red"
                size="2xl"
              />
            </button>
          </button>
        </div>
        <div className="modal-body text-center">
          <Alert>Thank you for signing up with StoryTalk.</Alert>
          <img
            src="/assets/images/storytalk-village-logo.svg"
            alt=""
            className="mt-2 mb-3"
          />
          <p>
            Kindly check your mailbox to complete the verification process. If
            you have not received an email in your mailbox, please reach out to
            support@storytalkpartners.com for assistance
          </p>
          <button
            className="st-btn st-btn-primary"
            onClick={() => handleSignIn()}
          >
            Sign in
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ThanksForSignUpModal;
