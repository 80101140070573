import React, { useState, useEffect } from "react";
import { Carousel, CarouselItem, CarouselIndicators } from "reactstrap";

const items = [
  {
    src: "/assets/images/slideHomeBanner1.jpg",
    altText: "Story talk",
    caption: "Story talk",
  },
  {
    src: "/assets/images/slideHomeBanner2.jpg",
    altText: "Story talk",
    caption: "Story talk",
  },
  {
    src: "/assets/images/slideHomeBanner3.jpg",
    altText: "Story talk",
    caption: "Story talk",
  },
  {
    src: "/assets/images/slideHomeBanner4.jpg",
    altText: "Story talk",
    caption: "Story talk",
  },
  {
    src: "/assets/images/slideHomeBanner5.jpg",
    altText: "Story talk",
    caption: "Story talk",
  },
];

function HomeSlider(args: any) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: any) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, 9000);

    return () => {
      clearInterval(interval);
    };
  }, [activeIndex]);

  const slides = items.map((item, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
        className="carousel-content"
      >
        <img src={item.src} alt={item.altText} className="home-carousel-img" />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      {...args}
      className="st-home-carousel"
      interval={false}
    >
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {slides}
    </Carousel>
  );
}

export default HomeSlider;
