import React from "react";

function ContactImage() {
  return (
    <div>
      <div className="upload-video-banner">
        <div className="upload-video-full">
          <img
            src="/assets/images/victoria-strukovskaya-Xc0ul_aR3Ss-unsplash.png"
            alt="my-profile-img"
            className="my-profile-img"
          />
          <div className="container">
            <div className="upload-video-details">
              <h1>Get in touch with us</h1>
              <p>
                Drop us a line and share your thoughts. <br /> We'd love to hear
                from you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactImage;
