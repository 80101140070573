import API_URL from "./apiConfig/api.config";
import apiCall from "./apiConfig/apiCall.config";

export async function forgotPassword(data: any) {
  try {
    return await apiCall.post(API_URL.USER.FORGOT_PASSWORD, data);
  } catch (e) {
    throw e;
  }
}

export async function registerUser(data: any) {
  try {
    return await apiCall.post(API_URL.USER.REGISTER, data);
  } catch (e) {
    throw e;
  }
}

export async function getAccessToken(data: any) {
  try {
    return await apiCall.post(API_URL.USER.ACCESS_TOKEN, data);
  } catch (e) {
    throw e;
  }
}

export async function getProfileDetails() {
  try {
    return await apiCall.get(API_URL.USER.PROFILE);
  } catch (e) {
    throw e;
  }
}

export async function getMyStories(data: any, pageNo?: number) {
  try {
    let url: string = API_URL.USER.MY_STORIES;
    if (pageNo) url += "?page=" + pageNo;
    return await apiCall.post(url, data);
  } catch (e) {
    throw e;
  }
}

export async function resetPassword(data: any) {
  try {
    return await apiCall.post(API_URL.USER.RESET_PASSWORD, data);
  } catch (e) {
    throw e;
  }
}

export async function updateProfile(data: any, header?: any) {
  try {
    return await apiCall.patch(API_URL.USER.UPDATE_PROFILE, data, header);
  } catch (e) {
    throw e;
  }
}

export async function verifyEmail(token: string) {
  try {
    return await apiCall.get(API_URL.USER.VERIFY_EMAIL + "?token=" + token);
  } catch (e) {
    throw e;
  }
}
export async function setNewPassword() {
  try {
    return await apiCall.get(API_URL.USER.VERIFY_EMAIL);
  } catch (e) {
    throw e;
  }
}
export async function contactUs(data: any) {
  try {
    return await apiCall.post(API_URL.USER.CONTACT_US, data);
  } catch (e) {
    throw e;
  }
}
export async function resentVerification(data: any) {
  try {
    return await apiCall.post(API_URL.USER.Resent_VERIFICATION, data);
  } catch (e) {
    throw e;
  }
}
