import React from "react";
import { CategoryCardProps } from "../../../model/village.model";
const CategoryCard: React.FC<CategoryCardProps> = ({
  catId,
  imageSrc,
  title,
  onClick,
}) => {
  return (
    <div
      className="col-lg-3 col-sm-6  col-md-4 st-cursor-pointer"
      onClick={() => onClick(catId, title)}
    >
      <div className="st-category-card |  position-relative">
        <img className="st-img |  w-100" src={imageSrc} alt={title} />
        <div className="st-content">
          <h5 className="st-title">{title}</h5>
        </div>
      </div>
    </div>
  );
};

export default CategoryCard;
