import { useEffect, useRef, useState } from "react";
import { ProfileNewStoryPageProps } from "../../model/profile.model";
import PageBanner from "../../components/shared/PageBanner/PageBanner";
import PromoBanner from "../../components/shared/PromoBanner/PromoBanner";
import FileUpload from "../../components/shared/FileUpload/FileUpload";
import commonService from "../../services/common.service";
import { chunkFileDetailsInitValue } from "../../model/newStory.model";
import axios from "axios";
import uuid from "react-uuid";
import API_SERVICES from "../../services";
import CreateNewStory from "../../components/CreateNewStory/CreateNewStory";
import { handleResponse } from "../../services/apiConfig/api.response.service";
import VideoCapture from "../../components/shared/VideoCapture/VideoCapture";
import NewStoryUploading from "../NewStory/NewStoryUploading";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import VideoPreviewModal from "../../components/shared/VideoPreviewModal/VideoPreviewModal";
import { storiesItem } from "../../model/shared.model";
import BetterStoryTellerCard from "../../components/shared/BetterStoryTellerCard/BetterStoryTellerCard";
import PageLoader from "../../components/shared/PageLoader/PageLoader";

const ProfileNewStoryPage = (props: ProfileNewStoryPageProps) => {
  const [isPreviewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const [currentVideo, setCurrentVideo] = useState<storiesItem | null>(null);
  const [videoSrc, seVideoSrc] = useState<string>("");
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [uploadingStatus, setUploadingStatus] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [isDirty, setFormDirty] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [uploadingPercentage, setUploadingPercentage] = useState(0);
  const [categoriesOptions, setCategoriesOptions] = useState<Array<any>>([]);
  const fileDetails = useRef<any>({});
  const chunkFileDetails = useRef<any>(
    commonService.cloneDeep(chunkFileDetailsInitValue)
  );
  let navigate = useNavigate();

  useEffect(() => {
    getCategories();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const onBeforeUnload = (event: any) => {
      if (isDirty) {
        const message: string = "Video not saved!";
        const e = event || window.event;
        e.preventDefault();
        if (e) {
          e.returnValue = message;
        }
        return message;
      }
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    if (videoSrc !== "" || isRecording) {
      const element: any = document.getElementById("upload-form-mystory");
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, [videoSrc, isRecording]);

  useEffect(() => {
    let element: any = document.getElementById("page-banner");
    if (isUploading && element) {
      const elemHeight: number = element.offsetHeight;
      window.scrollTo({
        top: elemHeight,
        behavior: "smooth",
      });
    }
  }, [isUploading]);

  const reader = new FileReader();

  reader.onloadend = (evt: any) => {
    const chunkFileDtls: any = chunkFileDetails.current;
    if (evt.target.readyState == FileReader.DONE) {
      var uri =
        chunkFileDtls.submitUriData.sas_url +
        "&comp=block&blockid=" +
        chunkFileDtls.blockIds[chunkFileDtls.blockIds.length - 1];
      var requestData = new Uint8Array(evt.target.result);

      const headers = {
        "x-ms-blob-type": "BlockBlob",
        "Content-Length": requestData.length,
        "Content-Type": chunkFileDtls.fileDetails.type,
      };

      axios.put(uri, requestData, { headers }).then((response) => {
        chunkFileDtls["bytesUploaded"] += requestData.length;

        chunkFileDtls.uploadStatus = (
          (chunkFileDtls["bytesUploaded"] / chunkFileDtls.fileDetails.size) *
          100
        ).toFixed(2);

        chunkFileDetails.current = chunkFileDtls;
        uploadFileInBlocks();
      });
    }
  };

  const clearChunkData = () => {
    chunkFileDetails.current = {};
    chunkFileDetails.current = commonService.cloneDeep(
      chunkFileDetailsInitValue
    );
    seVideoSrc("");
    setFormDirty(false);
    setIsRecording(false);
    let element: any = document.getElementById("page-banner");
    element?.scrollIntoView({ behavior: "smooth" });
  };

  const commitBlockList = () => {
    let { blockIds, submitUriData }: any = chunkFileDetails.current;
    var uri = submitUriData.sas_url + "&comp=blocklist";
    var requestBody = '<?xml version="1.0" encoding="utf-8"?><BlockList>';
    for (var i = 0; i < blockIds.length; i++) {
      requestBody += "<Latest>" + blockIds[i] + "</Latest>";
    }
    requestBody += "</BlockList>";
    const headers = {
      "Content-Length": requestBody.length,
      "Content-Type": "text/plain; charset=utf-8",
    };
    axios.put(uri, requestBody, { headers }).then((response) => {
      uploadStory();
    });
  };

  const getCategories = () => {
    API_SERVICES.VIDEO.GET_CATEGORIES().then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        const options: Array<any> = commonService.getDropdownOptions(
          responseData.data?.results
        );
        setCategoriesOptions(options);
      }
    });
  };

  const handlePlay = (video: storiesItem) => {
    setCurrentVideo(video);
    setPreviewModalOpen(true);
  };

  const handleVideoCapture = (fileDetails: any) => {
    const fileExtension: string =
      fileDetails?.type === "video/mp4" ? ".mp4" : ".webm";
    const chunkFileDtls: any = { ...chunkFileDetails.current };
    chunkFileDtls["selectedFile"] = fileDetails;
    chunkFileDtls["selectedFile"]["name"] = uuid() + fileExtension;
    chunkFileDtls["fileDetails"] = fileDetails;
    const fileSize: number = fileDetails.size;
    chunkFileDtls["totalBytesRemaining"] = fileSize;
    if (fileSize < chunkFileDtls.maxBlockSize) {
      chunkFileDtls.maxBlockSize = fileSize;
    }
    if (fileSize % chunkFileDtls.maxBlockSize == 0) {
      chunkFileDtls.numberOfBlocks = Math.floor(
        fileSize / chunkFileDtls.maxBlockSize
      );
    } else {
      chunkFileDtls.numberOfBlocks =
        Math.floor(fileSize / chunkFileDtls.maxBlockSize) + 1;
    }
    chunkFileDetails.current = chunkFileDtls;
    var url = URL.createObjectURL(fileDetails);
    setIsRecording(false);
    setFormDirty(true);
    seVideoSrc(url);
  };

  const handleDelete = () => {
    clearChunkData();
  };

  const handleFileSelect = (e: any, fileDetails: any) => {
    const chunkFileDtls: any = { ...chunkFileDetails.current };
    const files = e.target.files;
    chunkFileDtls["selectedFile"] = files[0];
    chunkFileDtls["fileDetails"] = fileDetails;
    const fileSize: number = fileDetails.size;
    chunkFileDtls["totalBytesRemaining"] = fileSize;
    if (fileSize < chunkFileDtls.maxBlockSize) {
      chunkFileDtls.maxBlockSize = fileSize;
    }
    if (fileSize % chunkFileDtls.maxBlockSize == 0) {
      chunkFileDtls.numberOfBlocks = Math.floor(
        fileSize / chunkFileDtls.maxBlockSize
      );
    } else {
      chunkFileDtls.numberOfBlocks =
        Math.floor(fileSize / chunkFileDtls.maxBlockSize) + 1;
    }
    var url = URL.createObjectURL(files[0]);
    setFormDirty(true);
    seVideoSrc(url);
    chunkFileDetails.current = chunkFileDtls;
  };

  const handleSave = (formData: any) => {
    fileDetails.current = formData;
    setIsUploading(true);
    setUploadingStatus("createToken");
    setUploadingPercentage(0);
    setSasUrl();
    setIsFetching(true);
  };

  const handleUploadSuccess = () => {
    clearChunkData();
    seVideoSrc("");
    setIsUploading(false);
    setUploadingPercentage(0);
    setFormDirty(false);
    navigate("/dashboard/profile");
  };

  const toggleRecordingSection = () => {
    setFormDirty(true);
    setIsRecording(!isRecording);
  };

  const pad = (number: number, length: number) => {
    var str = "" + number;
    while (str.length < length) {
      str = "0" + str;
    }
    return str;
  };

  const setSasUrl = () => {
    API_SERVICES.VIDEO.GET_SAS_URL().then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData?.ok) {
        const chunkFileDtls: any = chunkFileDetails.current;
        const baseUrl: string = responseData.data.sas_url;
        const indexOfQueryStart = baseUrl.indexOf("?");
        chunkFileDtls.submitUriData = responseData.data;
        chunkFileDtls.submitUriData.sas_url =
          baseUrl.substring(0, indexOfQueryStart) +
          "/" +
          chunkFileDtls.selectedFile.name +
          baseUrl.substring(indexOfQueryStart);

        setUploadingStatus("uploading");
        uploadFileInBlocks();
      }
    });
  };
  const uploadFileInBlocks = () => {
    const chunkFileDtls: any = chunkFileDetails.current;
    let {
      blockIdPrefix,
      blockIds,
      currentFilePointer,
      maxBlockSize,
      numberOfBlocks,
      selectedFile,
      totalBytesRemaining,
    } = chunkFileDtls;
    const percentageOfUpload = (blockIds.length / numberOfBlocks) * 100;

    setUploadingPercentage(percentageOfUpload);
    if (totalBytesRemaining > 0) {
      var fileContent = selectedFile.slice(
        currentFilePointer,
        currentFilePointer + maxBlockSize
      );
      var blockId = blockIdPrefix + pad(blockIds.length, 6);
      chunkFileDtls.blockIds.push(btoa(blockId));
      reader.readAsArrayBuffer(fileContent);
      chunkFileDtls.currentFilePointer += maxBlockSize;
      chunkFileDtls.totalBytesRemaining -= maxBlockSize;
      if (chunkFileDtls.totalBytesRemaining < maxBlockSize) {
        chunkFileDtls.maxBlockSize = chunkFileDtls.totalBytesRemaining;
      }
      chunkFileDetails.current = chunkFileDtls;
    } else {
      commitBlockList();
    }
  };

  const uploadStory = () => {
    setUploadingStatus("createStory");
    const chunkFileDtls: any = chunkFileDetails.current;
    const formData: any = fileDetails.current || {};
    const data: any = {
      ...formData,
      blob_url: chunkFileDtls.submitUriData.sas_url,
      asset: String(chunkFileDtls.submitUriData.id),
    };
    API_SERVICES.VIDEO.UPLOAD_STORY(data).then((data: any) => {
      setUploadingPercentage(100);
      setIsFetching(false);
    });
  };

  const createActions: any = {
    handleDelete: handleDelete,
    handleSave: handleSave,
  };

  return (
    <>
      <Helmet>
        <title>Story talk | Create new story</title>
      </Helmet>

      <PageLoader isLoading={isFetching} overlayOnly={true} />
      <VideoPreviewModal
        video={currentVideo}
        isOpen={isPreviewModalOpen}
        closeModal={() => setPreviewModalOpen(false)}
      />
      <PageBanner
        image="/assets/images/my-profile-banner.png"
        containerClass="st-page-banner-content"
        wrapperClass={"st-new-story-banner"}
      >
        <div className="container">
          <div className="uploading text-start">
            {videoSrc === "" && !isRecording ? (
              <>
                <h3>What's Your Story?</h3>
                <p>
                  You can record a story through the web cam or you can upload
                  from the files inside your folders. What would you like to do?
                </p>
                <div className="my-story-btns d-flex">
                  <FileUpload handleFileSelect={handleFileSelect} />
                  <button
                    className="st-btn st-btn-primary mb-3 m-lg-0"
                    onClick={() => toggleRecordingSection()}
                  >
                    Record now
                  </button>
                </div>
              </>
            ) : (
              <>
                <h1>My Profile</h1>
              </>
            )}
          </div>
        </div>
      </PageBanner>
      {isRecording && (
        <VideoCapture
          handleCapture={handleVideoCapture}
          handleCancel={toggleRecordingSection}
        />
      )}
      {videoSrc !== "" && !isUploading && (
        <CreateNewStory
          actions={createActions}
          videoSrc={videoSrc}
          categories={categoriesOptions}
        />
      )}
      {isUploading && (
        <NewStoryUploading
          handleSuccess={() => handleUploadSuccess()}
          uploadingStatus={uploadingStatus}
          completed={uploadingPercentage}
        />
      )}

      <BetterStoryTellerCard onPlay={handlePlay} />
      <PromoBanner />
    </>
  );
};

export default ProfileNewStoryPage;
