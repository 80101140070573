export interface ProfileNewStoryPageProps {}

export interface CreateNewStoryProps {
  actions: {
    handleDelete: Function;
    handleSave: Function;
  };
  categories: Array<any>;
  videoSrc: string;
}

export const timerInitVal = {
  elapsedTime: 0,
  timer: 0,
  timeTotal: 300000,
  timeCount: 20,
  timeStart: 0,
  cFlag: undefined,
};

export interface profileFormData {
  age_range: string;
  city: string;
  country: string;
  display_name: string;
  email: string;
  first_name: string;
  gender: string;
  image: string;
  is_verified: boolean;
  last_name: string;
}
export interface MyProfileUpdateProps {
  formData: profileFormData | null;
  handleUpload: Function;
  onSave: Function;
}
