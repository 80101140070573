import React, { FunctionComponent, useMemo } from "react";
import { AllStoriesProps } from "../../../model/village.model";
import { Alert, Spinner } from "reactstrap";
import CarouselCard from "../../shared/CarouselCard/CarouselCard";

const AllStories: FunctionComponent<AllStoriesProps> = ({
  stories,
  isFetching,
  loadMore,
  onPlay,
  showLoadMore,
  title,
  toggleCategoryNav,
}: AllStoriesProps) => {
  return (
    <>
      <div className=" ">
        <div className="container">
          <div className="flex-header text-start">
            <h5 className="d-flex w-100 g-2 justify-content-between flex-wrap">
              {title}
              <div
                className="st-cursor-pointer my-2 my-sm-0"
                onClick={() => toggleCategoryNav()}
              >
                <img
                  src="/assets/images/story-category-icon.svg"
                  alt="category"
                />
                Select story category
              </div>
            </h5>
          </div>
          <div className="row gx-1 gy-3 pb-4 ">
            {!isFetching && stories.length === 0 && (
              <Alert color="warning text-start">No stories to show</Alert>
            )}
            {stories.map((item: any, index: number) => (
              <div
                className="col-xl-3 col-lg-4 col-sm-6  st-cursor-pointer"
                onClick={() => onPlay(item)}
              >
                <CarouselCard key={index} video={item} onPlay={onPlay} />
              </div>
            ))}
            {isFetching && (
              <div className="text-center my-3">
                <Spinner color="danger"></Spinner>{" "}
              </div>
            )}
            {showLoadMore && (
              <div className="text-center mt-4">
                <button
                  type="button"
                  className="st-btn st-btn-primary"
                  style={{ margin: 0 }}
                  onClick={() => loadMore()}
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AllStories;
