import { FunctionComponent, useState } from "react";
import { CreateNewStoryProps } from "../../model/profile.model";
import { Player } from "video-react";
import Select from "react-select";
import ConfirmModal from "../shared/ConfirmModal/ConfirmModal";
import FormInput from "../Contact/FormInput";
import { CONSTANTS } from "../../constants";

const CreateNewStory: FunctionComponent<CreateNewStoryProps> = (
  props: CreateNewStoryProps
) => {
  const { actions, videoSrc, categories } = props;
  const [selectedCategory, setCategory] = useState<any>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isCategorySelected, setIsCategorySelected] = useState(false);
  const { handleDelete, handleSave } = actions;

  const [isTitleValid, setIsTitleValid] = useState("");
  const [isDescriptionValid, setIsDescriptionValid] = useState("");
  const [isCategoryValid, setIsCategoryValid] = useState(true);
  const [showDelete, setIsShowDelete] = useState(false);
  const initDelete = () => {
    setIsShowDelete(true);
  };
  const handleCategoryChange = (selectedOption: any) => {
    setCategory(selectedOption);
    setIsCategorySelected(true);
    setIsCategoryValid(!!selectedOption);
  };
  const hideConfirmModal = () => {
    setIsShowDelete(false);
  };
  const submitToVillage = () => {
    if (!isCategorySelected) setIsCategoryValid(false);
    if (!title) setIsTitleValid(CONSTANTS.VALIDATIONS.FIELD_REQUIRED);
    if (!description)
      setIsDescriptionValid(CONSTANTS.VALIDATIONS.FIELD_REQUIRED);

    if (!isCategorySelected || !title || !description) return;

    const formData: any = {
      title: title,
      description: description,
      category: [selectedCategory.value],
    };
    handleSave(formData);
  };

  const videoWidth: any = "100%";

  return (
    <>
      <div
        className="uploading upload-form-mystory container "
        id="upload-form-mystory"
      >
        <h3>Story Details</h3>
        <div className="row">
          <div className="col-lg-6 video-form-res mb-3 mb-lg-0">
            <div className="custom-video-player">
              <Player
                src={videoSrc}
                fluid={false}
                width={videoWidth}
                height={400}
              />
            </div>
          </div>
          <div className="col-lg-6 story-form">
            <FormInput
              formData={{
                id: "title",
                label: "Story title",
                placeholder: "Story Title",
                value: title,
                required: true,
                invalid: isTitleValid,
              }}
              actions={{
                handleChange: (value: string) => {
                  setTitle(value);
                  if (!value && !isTitleValid)
                    setIsTitleValid(CONSTANTS.VALIDATIONS.FIELD_REQUIRED);
                  if (value && isTitleValid) setIsTitleValid("");
                },
              }}
            />
            <FormInput
              inputClass="st-textarea"
              containerClass="st-form-floating-textarea"
              formData={{
                type: "textarea",
                placeholder: "Description",
                id: "description",
                label: "Description",
                value: description,
                required: true,
                invalid: isDescriptionValid,
              }}
              actions={{
                handleChange: (value: string) => {
                  setDescription(value);
                  if (!value && !isDescriptionValid)
                    setIsDescriptionValid(CONSTANTS.VALIDATIONS.FIELD_REQUIRED);
                  if (value && isDescriptionValid) setIsDescriptionValid("");
                },
              }}
            />
            <div className="form-floating mb-3">
              <label
                htmlFor="category"
                className={`st-label ${
                  selectedCategory === null ? "" : " st-select-label"
                }`}
              >
                Category<span style={{ color: "red" }}>*</span>
              </label>

              <Select
                id="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                options={categories}
                className={`st-select  ${!isCategoryValid ? " invalid" : ""}`}
                classNamePrefix={`st-select`}
                classNames={{
                  indicatorSeparator: (state) =>
                    state.isFocused ? "d-none" : "d-none",
                  dropdownIndicator: (state) =>
                    state.isFocused ? "st-btn-menu-caret" : "st-btn-menu-caret",
                }}
                placeholder=""
              />
              {!isCategoryValid && (
                <div className="invalid-tooltip d-block">
                  {CONSTANTS.VALIDATIONS.FIELD_REQUIRED}
                </div>
              )}
            </div>
            <div className="d-flex story-decision-btn">
              <button className="btn btn-link p-0 mb-2" onClick={initDelete}>
                Delete Story
              </button>
              <button
                className="st-btn st-btn-primary mb-2"
                onClick={submitToVillage}
              >
                Send to village
              </button>
            </div>
            <ConfirmModal
              isShow={showDelete}
              onConfirm={handleDelete}
              onCancel={hideConfirmModal}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewStory;
