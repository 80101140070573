import { FunctionComponent, useEffect } from "react";
import ProgressBar from "../../components/shared/ProgressBar/ProgressBar";
import { NewStoryUploadingProps } from "../../model/shared.model";
import { Spinner } from "reactstrap";

const NewStoryUploading: FunctionComponent<NewStoryUploadingProps> = (
  props: NewStoryUploadingProps
) => {
  useEffect(() => {
    let timeOutFn: any;
    if (props.completed === 100) {
      timeOutFn = setTimeout(() => {
        props.handleSuccess();
      }, 2000);
    }
    return () => {
      clearTimeout(timeOutFn);
    };
  }, [props.completed]);
  const status: string = props.uploadingStatus;
  return (
    <div className="uploading container my-5" id="uploading-video">
      <div className="row">
        <div className="col-lg-5 text-start">
          <h3 className="">Uploading your Video</h3>
          {status === "createToken" ? (
            <>
              <div className="d-flex align-items-center">
                <span className="me-2 st-text-sand">
                  Processing your video. Please wait.
                </span>
                <Spinner color="danger"></Spinner>
              </div>
            </>
          ) : status === "uploading" ? (
            <>
              <p>
                Please wait while we send your video to The Village.{" "}
                {Math.ceil(props.completed) + "%"}
              </p>

              <ProgressBar fill={props.completed + "%"} />
              {props.completed === 100 && (
                <a href="my-story-details.html" className="file-upload-color">
                  File Uploaded successfully
                </a>
              )}
            </>
          ) : (
            <>
              <p className="d-flex align-items-center">
                <span className="me-2"> Creating your story. Please wait.</span>
                <Spinner color="danger"> </Spinner>
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewStoryUploading;
