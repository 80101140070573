import React from "react";
import Layout from "../../layouts/Layout";
import ContactBanner from "../../components/ContactBanner/ContactBanner";
import PromoBanner from "../../components/shared/PromoBanner/PromoBanner";
import ContactImage from "../../components/ContactImage/ContactImage";
import { Helmet } from "react-helmet";

function Contact() {
  return (
    <div>
      <Helmet>
        <title>Story talk | Contact</title>
      </Helmet>
      <Layout>
        <ContactImage />
        <ContactBanner />
        <PromoBanner />
      </Layout>
    </div>
  );
}

export default Contact;
