import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home/Home";
import AboutUs from "../pages/AboutUs/AboutUs";
import NewStory from "../pages/NewStory/NewStory";
import Faq from "../pages/Faq/Faq";
import Contact from "../pages/Contact/Contact";
import Profile from "../pages/Profile/Profile";
import LayoutProtected from "../layouts/LayoutProtected";
import { AuthProvider, UserContext } from "../context/userContext";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import ProfileNewStoryPage from "../pages/ProfileNewStory/ProfileNewStory";
import ResetPassword from "../components/User/ResetPassword/ResetPassword";
import VerifyAccountPage from "../pages/VerifyAccount/VerifyAccount";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import VillagePage from "../pages/Village/Village";
import CategoryDetailsPage from "../pages/CategoryDetails/CategoryDetails";
import GoToTop from "../components/GoToTop/GoToTop";

const MainRoute = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home/:action?" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/village" element={<VillagePage />} />
        <Route
          path="/village/category/:categoryId/:catName"
          element={<CategoryDetailsPage />}
        />
        <Route path="/email-verify" element={<VerifyAccountPage />} />
        <Route path="/dashboard" element={<LayoutProtected />}>
          <Route path={"profile"} element={<Profile />} />
          <Route path={"new-story"} element={<ProfileNewStoryPage />} />
          <Route path={"new-story1"} element={<NewStory />} />
        </Route>
        <Route path="/password-reset" element={<ResetPassword />} />
        <Route path="/password-reset/:uid/:token" element={<ResetPassword />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <GoToTop />
    </AuthProvider>
  );
};

export default MainRoute;
