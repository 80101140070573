import { FunctionComponent, ReactNode } from "react";
import Footer from "../components/shared/footer";

interface LayoutFullScreenProps {
  children: ReactNode;
}

const LayoutFullScreen: FunctionComponent<LayoutFullScreenProps> = (
  props: LayoutFullScreenProps
) => {
  return (
    <div className="st-layout-full-screen">
      <div className="container ">
        <div className="d-block my-4 mx-auto">
          <img
            className="nav-img"
            src="/assets/images/storytalk-logo-reversed-nav.png"
            alt="nav-img"
          />
        </div>
      </div>
      <div className="st-full-bg">{props.children}</div>
      <Footer />
    </div>
  );
};

export default LayoutFullScreen;
