import { useState } from "react";
import { Alert, Modal } from "reactstrap";
import { ForgotPasswordProps } from "../../../model/user.model";
import FormInput from "../../Contact/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
function ForgotPassword(props: ForgotPasswordProps) {
  const { handleSignIn, isModalOpen, toggleModal, isSuccess } = props;
  const [email, setEmail] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState("");
  const sendMail = (e: any) => {
    e.preventDefault();
    if (email.trim() === "") {
      setIsEmailInvalid("This field is required");
    } else {
      handleSignIn({ email });
    }
  };
  const handleChange = (value: string) => {
    setEmail(value);
    setIsEmailInvalid("");
  };
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      className="st-custom-modal"
      centered
    >
      <div className="modal-content">
        <div className="modal-header d-block mt-3">
          <div className="form-head">
            <img src="/assets/images/storytalk-village-logo.svg" alt="" />
            <h2>Forgot password</h2>
          </div>{" "}
          {isSuccess && (
            <Alert>
              If a matching account was found an email with a link to reset your
              password has been sent to your e-mail address.
            </Alert>
          )}
          <button
            type="button"
            className="st-close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={toggleModal}
          >
            <button>
              <FontAwesomeIcon
                icon={faTimesCircle}
                className="st-text-red"
                size="2xl"
              />
            </button>
          </button>
        </div>

        <div className="modal-body">
          <form onSubmit={sendMail}>
            <FormInput
              formData={{
                id: "email",
                label: "Email address",
                placeholder: "Email address",
                value: email,
                type: "email",
                required: true,
                invalid: isEmailInvalid,
              }}
              actions={{ handleChange: handleChange }}
              containerClass={"st-text-sand"}
            />
            <div className="submit">
              <button type="submit" className="st-btn st-btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default ForgotPassword;
