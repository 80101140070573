import { FunctionComponent } from "react";
import { PageLoaderProps } from "../../../model/shared.model";
import { Spinner } from "reactstrap";

const PageLoader: FunctionComponent<PageLoaderProps> = (
  props: PageLoaderProps
) => {
  const showContent: boolean =
    (typeof props?.overlayOnly !== "undefined" &&
      props?.overlayOnly === false) ||
    typeof props?.overlayOnly === "undefined"
      ? true
      : false;
  return (
    <>
      <div className={`st-page-loader ${!props.isLoading && "d-none"}`}>
        <div className="">
          {showContent && (
            <>
              <Spinner color="danger" type="grow"></Spinner>
              <div className="st-text st-bg-coffee st-text-white py-1 px-3 rounded">
                Loading...
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PageLoader;
