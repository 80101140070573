import { FunctionComponent, useEffect, useRef, useState } from "react";
import { CategoryItem, VillagePageProps } from "../../model/village.model";
import { Helmet } from "react-helmet";
import Layout from "../../layouts/Layout";
import PromoBanner from "../../components/shared/PromoBanner/PromoBanner";
import VillageSlider from "../../components/Village/VillageSlider/VillageSlider";
import API_SERVICES from "../../services";
import { storiesItem } from "../../model/shared.model";
import VideoPreviewModal from "../../components/shared/VideoPreviewModal/VideoPreviewModal";
import AllCategories from "../../components/Village/AllCategories/AllCategories";
import MyStories from "../../components/Profile/MyStories/MyStories";
import AllStories from "../../components/Village/AllStories/AllStories";
import { handleResponse } from "../../services/apiConfig/api.response.service";
import CategoryNavbar from "../../components/Village/CategoryNavbar/CategoryNavbar";
import commonService from "../../services/common.service";

const VillagePage: FunctionComponent<VillagePageProps> = (
  props: VillagePageProps
) => {
  const [isFetchingSlider, setFetchingSlider] = useState(false);
  const [isFetchingCategories, setFetchingCategories] = useState(false);
  const [isFetchingFeatured, setFetchingFeatured] = useState(false);
  const [isFetchingRecent, setFetchingRecent] = useState(false);
  const [isFetchingAllStories, setFetchingAllStories] = useState(false);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const [featuredStories, setFeaturedStories] = useState<any>([]);
  const [allCategories, setAllCategories] = useState<any>([]);
  const [allStories, setAllStories] = useState<any>([]);
  const [slider, setSlider] = useState<any>([]);
  const [recentlyAddedStories, setRecentlyAddedStories] = useState<any>([]);
  const [currentVideo, setCurrentVideo] = useState<storiesItem | null>(null);
  const [isCatNavOpen, setCatNavOpen] = useState(false);

  const fetchData: any = useRef({
    categoryPage: 0,
    categoryTotalCount: 0,
    categoryNextPageUrl: 0,
    storyPage: 0,
    storyTotalCount: 0,
    storyNextPageUrl: 0,
  });

  useEffect(() => {
    loadItems();
  }, []);

  const handlePlay = (video: storiesItem) => {
    setCurrentVideo(video);
    setPreviewModalOpen(true);
  };

  const loadAllStories = (pageNo?: number) => {
    const payload: any = {
      category: 0,
      featured_carousel: 0,
      is_featured: 0,
    };
    setFetchingAllStories(true);
    API_SERVICES.VIDEO.GET_STORY_LIST(payload, pageNo).then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData.ok) {
        const updatedStories: Array<storiesItem> = [...allStories];
        const newStories: Array<storiesItem> = responseData.data.results;
        if (data.data.next !== null) {
          const page: string = data.data.next.split("=")[1];
          fetchData.current.storyPage = page;
        }
        fetchData.current.storyTotalCount = data.data.count;
        fetchData.current.storyNextPageUrl = data.data.next;
        setFetchingAllStories(false);
        updatedStories.push(...newStories);
        setAllStories(updatedStories);
      }
    });
  };

  const loadAllStoriesMore = () => {
    if (fetchData.current.storyNextPageUrl) {
      loadAllStories(fetchData.current.storyPage);
    }
  };

  const loadCategories = (page?: number) => {
    setFetchingCategories(true);
    const updatedCategories: Array<CategoryItem> = [...allCategories];
    API_SERVICES.VIDEO.GET_CATEGORIES(page).then((data: any) => {
      const allCategories = data.data.results.map((category: any) => {
        return {
          imageSrc: category.image,
          title: category.name,
          categoryId: category.id,
        };
      });
      setFetchingCategories(false);
      fetchData.current.categoryPage = fetchData.current.categoryPage + 1;
      fetchData.current.categoryTotalCount = data.data.count;
      fetchData.current.categoryNextPageUrl = data.data.next;
      updatedCategories.push(...allCategories);
      setAllCategories(updatedCategories);
    });
  };

  const loadCategoryMore = () => {
    if (fetchData.current.categoryNextPageUrl) {
      loadCategories(fetchData.current.categoryPage);
    }
  };

  const loadItems = () => {
    setFetchingAllStories(true);
    setFetchingCategories(true);
    setFetchingFeatured(true);
    setFetchingRecent(true);
    setFetchingSlider(true);

    loadStories(
      {
        category: 0,
        featured_carousel: 0,
        is_featured: 1,
      },
      setFeaturedStories,
      "featured"
    );

    loadStories(
      {
        category: 0,
        featured_carousel: 0,
        is_featured: 0,
      },
      setRecentlyAddedStories,
      "recent"
    );
    loadCategories();
    loadSlider();
    loadAllStories();
  };

  const loadSlider = () => {
    const data: any = {
      category: 0,
      featured_carousel: 1,
      is_featured: 0,
    };
    API_SERVICES.VIDEO.GET_STORY_LIST(data).then((data: any) => {
      setSlider(data.data);
      setFetchingSlider(false);
    });
  };

  const loadStories = (data: any, setData: Function, area: string) => {
    API_SERVICES.VIDEO.GET_STORY_LIST(data).then((data: any) => {
      if (area === "featured") setFetchingFeatured(false);
      else setFetchingRecent(false);
      setData(data.data);
    });
  };

  const toggleCategoryNav = () => {
    setCatNavOpen(!isCatNavOpen);
  };

  return (
    <>
      <Helmet>
        <title>Story talk | Village </title>
      </Helmet>

      <VideoPreviewModal
        video={currentVideo}
        isOpen={isPreviewModalOpen}
        closeModal={() => setPreviewModalOpen(false)}
      />
      <Layout containerClass="st-village-page">
        <CategoryNavbar
          isOpen={isCatNavOpen}
          categories={allCategories}
          toggleNav={toggleCategoryNav}
        />
        <VillageSlider
          sliderData={slider}
          onPlay={handlePlay}
          isFetching={isFetchingSlider}
        />
        <AllCategories
          categories={allCategories}
          isFetching={isFetchingCategories}
          loadMore={loadCategoryMore}
          showLoadMore={fetchData.current.categoryNextPageUrl}
        />
        <MyStories
          stories={featuredStories}
          isFetching={isFetchingFeatured}
          onPlay={handlePlay}
          title="Featured Stories"
          containerClass="border-top pb-5"
        />
        <MyStories
          stories={recentlyAddedStories}
          isFetching={isFetchingRecent}
          onPlay={handlePlay}
          title="Recently added Stories"
          containerClass="border-top pb-5 st-bg-coffee"
        />
        <AllStories
          isFetching={isFetchingAllStories}
          loadMore={loadAllStoriesMore}
          stories={allStories}
          onPlay={handlePlay}
          showLoadMore={fetchData.current.storyNextPageUrl}
          title={"All Stories"}
          toggleCategoryNav={toggleCategoryNav}
        />
        <PromoBanner />
      </Layout>
    </>
  );
};

export default VillagePage;
