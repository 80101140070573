const avatarData = [
  {
    name: "Kevin Meagher",
    role: "Co-Founder",
    description:
      "Kevin is a multi-Emmy award winning writer- producer-director & production company founder / CEO, who has been practicing storytelling for years.",
    image: "/assets/images/profile-pic.png",
  },
  {
    name: "Tim Gariggan",
    role: "Co-Founder",
    description:
      "Tim is the former founder of a global brand agency, receiving numerous Emmys and national awards for telling stories about people and brands.",
    image: "/assets/images/profile-pic2.png",
  },
  {
    name: "Bill Benjamin",
    role: "Advisor",
    description:
      "Bill is an experienced CEO, managing partner, and board member with experience in investment banking, technology, and business services. Bill is an avid cyclist, photographer, and wildlife storyteller.",
    image: "/assets/images/profile-pic3.png",
  },
];

function Avatar() {
  return (
    <section className="team">
      <div className="container">
        <div className="team_text">
          <h3>Our Team</h3>
          <p>
            Storyland Partners LLC. Presents <span> Story Talk </span>
          </p>
        </div>
        <div className="cards">
          {avatarData.map((data, index) => {
            return (
              <div className="card1" key={index}>
                <img src={data.image} alt="profile pic" />
                <div className="st-content  ">
                  <h4>{data.name}</h4>
                  <h5>{data.role}</h5>
                  <p className="text-start">{data.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Avatar;
