import { FunctionComponent, useMemo, useState } from "react";
import { MyStoriesProps, storiesItem } from "../../../model/shared.model";
import CarouselCard from "../../shared/CarouselCard/CarouselCard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Alert, Button, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";

const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group">
      <Button
        className={currentSlide === 0 ? "disable" : ""}
        onClick={() => previous()}
      >
        Previous
      </Button>
      <Button onClick={() => next()}>Next </Button>
    </div>
  );
};
const MyStories: FunctionComponent<MyStoriesProps> = (
  props: MyStoriesProps
) => {
  const { onPlay, stories } = props;

  const getStoryCard = (stories: storiesItem[]) => {
    return (
      stories &&
      stories.map((item: storiesItem, index: number) => {
        return <CarouselCard key={index} video={item} onPlay={onPlay} />;
      })
    );
  };

  const slides = useMemo(() => {
    if (stories.results) return getStoryCard(stories.results);
    else return <></>;
  }, [JSON.stringify(stories.results)]);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1201 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1200, min: 906 },
      items: 3,
    },
    tabletPortrait: {
      breakpoint: { max: 905, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className={props?.containerClass}>
        <div className="container st-custom-carousel">
          {props.title !== "" && (
            <div className="flex-header  text-start">
              <h5>{props.title}</h5>
            </div>
          )}
          {stories.results?.length ? (
            <Carousel
              // arrows={false}
              // renderButtonGroupOutside={true}
              // customButtonGroup={<ButtonGroup />}
              responsive={responsive}
            >
              {slides}
            </Carousel>
          ) : props?.isFetching ? (
            <div className="my-4 mx-auto">
              <Spinner color="danger"> </Spinner>
            </div>
          ) : (
            <>{props?.noContentAlert && <>{props?.noContentAlert()}</>}</>
          )}
        </div>
      </div>
    </>
  );
};

export default MyStories;
