import React from "react";
import CategoryCard from "../CategoryCard/CategoryCard";
import { VillageCategoryProps } from "../../../model/village.model";
import { Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";

const AllCategories = ({
  categories,
  isFetching,
  loadMore,
  showLoadMore,
}: VillageCategoryProps) => {
  const navigate = useNavigate();

  const handleRedirection = (id: number, title: string) => {
    navigate(`/village/category/${id}/${title}`);
  };

  return (
    <div className="st-bg-coffee pb-4">
      <div className="container">
        <div className="flex-header text-start">
          <h5>All categories </h5>
        </div>
        <div className="row g-2 ">
          {categories.map((category: any, index: number) => (
            <CategoryCard
              key={index}
              catId={category.categoryId}
              imageSrc={category.imageSrc}
              title={category.title}
              onClick={handleRedirection}
            />
          ))}
        </div>

        {isFetching && (
          <div className="text-center my-3">
            <Spinner color="danger"></Spinner>{" "}
          </div>
        )}
        {showLoadMore && (
          <div className="text-center my-4">
            <button
              type="button"
              className="st-btn st-btn-primary"
              style={{ margin: 0 }}
              onClick={() => loadMore()}
            >
              Load More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllCategories;
