import React, { useEffect } from "react";
import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import MainRoute from "./routes/MainRoute";
import { Toaster } from "react-hot-toast";
import ReactGA from "react-ga4";

ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || "");
function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: window.location.search,
    });
  }, []);
  return (
    <div className="App">
      <Toaster
        position="bottom-center"
        toastOptions={{
          error: {
            className: "st-toast-error",
          },
          success: {
            className: "st-toast-success",
          },
          duration: 4000,
        }}
      />
      <BrowserRouter>
        <MainRoute />
      </BrowserRouter>
    </div>
  );
}

export default App;
