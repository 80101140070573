import React from "react";
import { NavLink } from "react-router-dom";

function VillageBanner() {
  return (
    <div>
      <div className="st-home-main-content text-start">
        <div className="container">
          <div className="st-home-2column row">
            <div className="col-lg-6  st-home-column-content  mb-4 mb-lg-0 d-flex">
              <img
                src="/assets/images/storytalk-village-logo.png"
                alt="storytalk-village-logo"
              />
              <h3>
                The ever-changing collection of authentic personal stories
              </h3>
              <p>
                From overcoming obstacles, reliving a hilarious moment, or
                surviving a near-death experience, telling your personal story
                has the power to change someone’s life and their story will
                brighten yours.
              </p>
              <NavLink to={"/village"} className="st-btn st-btn-primary">
                See for yourself
              </NavLink>
            </div>
            <NavLink
              to={"/village"}
              className="col-lg-6  | st-home-column-tile"
            >
              <img
                src="/assets/images/villageTile.png"
                alt="villageTile"
                id="home-tile-image"
              />

              <h2 className="villagebanner">
                <span style={{ color: "white" }}>
                  It’s time to tell
                  <br />
                  <span>
                    your <span className="ms-2 st-text-red"> Story</span>
                  </span>
                </span>
              </h2>
            </NavLink>
            <img
              src="/assets/images/storytalk-village-logo.png"
              alt="storytalk-village-logo"
              id="on-off-village-st-logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VillageBanner;
