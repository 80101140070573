import React, { useState, useEffect } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
  Spinner,
  Badge,
} from "reactstrap";
import { CategoryItem, VillageSliderProps } from "../../../model/village.model";
import { storiesItem } from "../../../model/shared.model";

function VillageSlider(props: VillageSliderProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const sliders: storiesItem[] = props.sliderData?.results || [];

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === sliders.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? sliders.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: any) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      next();
    }, 9000);

    return () => {
      clearInterval(interval);
    };
  }, [activeIndex]);

  const slides = sliders.map((item: any, index: number) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
        className="carousel-content"
      >
        <div className="container">
          {item?.media_url && (
            <div
              className="st-play-btn  st-cursor-pointer"
              onClick={() => props.onPlay(item)}
            >
              <img
                src="/assets/video-icons/video-play-button.svg"
                alt="video-play-button"
                id="v-play-btn"
              />
            </div>
          )}
          <div className="carousel-content-details">
            <div className="st-categories d-flex gap-2">
              {item.category_data?.map((category: any, ind: number) => (
                <Badge color="danger" key={ind}>
                  {category.name}
                </Badge>
              ))}
            </div>
            <div className="st-meta-content">
              <h2 className="st-title mt-3">{item.title}</h2>
              <p className="st-author">{item.author.first_name}</p>
            </div>
          </div>
        </div>
        <img
          src={item.carousel_image}
          alt={item.title}
          className="carousel-img "
        />
      </CarouselItem>
    );
  });

  return (
    <>
      {props.isFetching && (
        <div className="text-center my-3">
          <Spinner color="danger"></Spinner>{" "}
        </div>
      )}
      <Carousel
        activeIndex={activeIndex}
        next={next}
        previous={previous}
        className="st-village-carousel"
        interval={false}
      >
        <CarouselIndicators
          items={sliders}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
      </Carousel>
    </>
  );
}

export default VillageSlider;
