import React from "react";

function Mission() {
  return (
    <div>
      <div className="upload-video-banner st-about-us-banner">
        <div className="upload-video-full">
          <img
            src="/assets/images/victoria-strukovskaya-Xc0ul_aR3Ss-unsplash-about.png"
            alt="my-profile-img"
            className="st-banner-img | w-100"
          />
          <div className="container ">
            <div className="upload-video-details | about-img">
              <div className="mission">
                <div className="mission_text">
                  <h3>Our Mission</h3>
                  <p>
                    To encourage, facilitate, and advance personal storytelling.
                    We believe it to be an unappreciated, all-encompassing life
                    skill. And that you don’t need to be a professional to tell
                    one. <br />
                    It’s time to tell your
                    <span className="mx-2 st-text-red">Stories.</span>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
