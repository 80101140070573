const API_URL = {
  USER: {
    ACCESS_TOKEN: "api/v1/token/",
    FORGOT_PASSWORD: "api/v1/forgot-password/",
    MY_STORIES: "api/v1/my-story-list/",
    PROFILE: "api/v1/my-profile/",
    REGISTER: "api/v1/register/",
    REFRESH_TOKEN: "api/v1/token/refresh/",
    RESET_PASSWORD: "api/v1/password-reset/",
    UPDATE_PROFILE: "api/v1/update-profile/",
    VERIFY_EMAIL: "/api/v1/email-verify/",
    CONTACT_US: "/api/v1/contact-us/create/",
    Resent_VERIFICATION: "/api/v1/resent-verification/",
  },
  VIDEO: {
    CATEGORY: "/api/v1/categories/",
    CREATE_ASSET: "/api/v1/create_asset/",
    UPLOAD_STORY: "api/v1/upload-story/",
    STORY_LIST: "api/v1/story-list/",
  },
};

export default API_URL;
