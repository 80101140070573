import React, { useState } from "react";
import { Alert, Modal } from "reactstrap";
import LayoutFullScreen from "../../../layouts/LayoutFullScreen";
import FormInput from "../../Contact/FormInput";
import { formInputController } from "../../../model/contact.formInput.model";
import { NavLink, Navigate, useParams } from "react-router-dom";
import API_SERVICES from "../../../services";
import { handleResponse } from "../../../services/apiConfig/api.response.service";
import { CONSTANTS } from "../../../constants";
import commonService from "../../../services/common.service";
import PageLoader from "../../shared/PageLoader/PageLoader";
import { useAuth } from "../../../context/userContext";
function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordInvalid, setPasswordInvalid] = useState("");
  const [isCPasswordInvalid, setCPasswordInvalid] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState("");
  let { uid, token } = useParams();

  const { user }: any = useAuth();
  if (user) {
    return <Navigate to="/dashboard/profile" />;
  }

  const handleChange = (value: string, itemData: formInputController) => {
    if (itemData.id === "password") {
      setPassword(value);
      handlePasswordValidation(value, confirmPassword);
    } else {
      setConfirmPassword(value);
      handlePasswordValidation(password, value);
    }
  };

  const handlePasswordValidation = (pswd: string, cnfPswd: string) => {
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,26}$/;
    if (!re.test(pswd)) {
      setPasswordInvalid(
        "Your password must be at least 8 characters including a special character and a number"
      );
    } else if (pswd.length < 8)
      setPasswordInvalid("Password length minimum 8.");
    else if (cnfPswd !== "" && cnfPswd !== pswd) {
      setPasswordInvalid("");
      setCPasswordInvalid("Password not matching");
    } else {
      setPasswordInvalid("");
      setCPasswordInvalid("");
    }
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsFetching(true);
    const payload: any = {
      uid: uid,
      token: token,
      new_password: password,
    };

    API_SERVICES.USER.RESET_PASSWORD(payload).then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData.ok) {
        setUpdateStatus(CONSTANTS.STATUS.SUCCESS);
        commonService.toastService(
          "You have successfully reset your password.",
          "success"
        );
      } else {
        const err: string = commonService.flattenObjectToString(
          responseData.data
        );
        commonService.toastService(err, "error");
        setError(err);
        setUpdateStatus(CONSTANTS.STATUS.FAILED);
      }
      setIsFetching(false);
    });
  };
  const inputData: formInputController[] = [
    {
      id: "password",
      type: "password",
      label: "Password",
      placeholder: "Password",
      value: password,
      invalid: isPasswordInvalid,
    },
    {
      id: "confirmPassword",
      type: "password",
      label: "Confirm password",
      placeholder: "Confirm Password",
      value: confirmPassword,
      invalid: isCPasswordInvalid,
    },
  ];
  return (
    <LayoutFullScreen>
      <PageLoader isLoading={isFetching} />
      <Modal isOpen={true} className="st-custom-modal" centered size="lg">
        <div className="modal-content">
          <div className="modal-header">
            <div className="form-head">
              <img src="/assets/images/storytalk-village-logo.svg" alt="" />
              <h5 className="st-text-sand">Please enter your new password</h5>
            </div>
          </div>
          <div className="modal-body  ">
            {updateStatus === "" ? (
              <form onSubmit={handleSubmit}>
                {inputData.map((formData: any, index: number) => (
                  <FormInput
                    key={index}
                    formData={formData}
                    actions={{ handleChange: handleChange }}
                    containerClass={"st-text-sand"}
                  />
                ))}
                <div className="d-sm-flex d-block text-center text-sm-start my-4 justify-content-between">
                  <NavLink to="/" className={"btn btn-link"}>
                    Go to home
                  </NavLink>
                  <button
                    type="submit"
                    className="st-btn st-btn-primary"
                    style={{ margin: 0 }}
                    disabled={
                      isPasswordInvalid !== "" || isCPasswordInvalid !== ""
                    }
                  >
                    Submit
                  </button>
                </div>
              </form>
            ) : updateStatus === CONSTANTS.STATUS.SUCCESS ? (
              <div>
                <Alert>
                  You have successfully reset your password. Please login
                </Alert>
                <div className="d-block text-center py-3">
                  <NavLink
                    to={"/home/" + CONSTANTS.URL_PARAMS.HOME.ACTION.login}
                    className={"st-btn st-btn-primary"}
                  >
                    Login
                  </NavLink>
                </div>
              </div>
            ) : (
              <div>
                <Alert color="danger" className="text-center">
                  {error}
                </Alert>
                <div className="d-block text-center py-3">
                  <NavLink
                    to={
                      "/home/" + CONSTANTS.URL_PARAMS.HOME.ACTION.forgotPassword
                    }
                    className={"st-btn st-btn-primary"}
                  >
                    Go to Reset password
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </LayoutFullScreen>
  );
}

export default ResetPassword;
