import React from "react";

import {
  BetterStoryTellerCardProps,
  iVideo,
} from "../../../model/shared.model";

const videoData: iVideo[] = [
  {
    thumbnail: "/assets/images/home-vc1-poster1.png",
    media_url:
      "https://ep-storytalk-live-mediakind-mediaservices.eastus.streaming.mediakind.com/608a08f6-48e3-4442-8b92-da670a4db022/StoryTalk_WhyTellaStory_1080.ism/manifest(format=m3u8-cmaf)",
    title: "Why You Should Tell Your Story",
  },
  {
    thumbnail: "/assets/images/home-vc1-poster2.png",
    media_url:
      "https://ep-storytalk-live-mediakind-mediaservices.eastus.streaming.mediakind.com/dae6c271-e909-40d0-ad42-500692216356/StoryTalk_StoryTellingTips_1080.ism/manifest(format=m3u8-cmaf)",
    title: "How to be a Better Storyteller",
  },
  {
    thumbnail: "/assets/images/storytalk-logo-reversed-nav.png",
    media_url:
      "https://ep-storytalk-live-mediakind-mediaservices.eastus.streaming.mediakind.com/130b5550-f0d9-437d-a972-b53bf5368303/StoryTalk About Us v.2 22_11_15.ism/manifest(format=m3u8-cmaf)",
    title: "About StoryTalk",
  },
];

function BetterStoryTellerCard(props: BetterStoryTellerCardProps) {
  const openModal = (video: iVideo): void => {
    props.onPlay(video);
  };

  return (
    <div className="home-video-section">
      <div className="container">
        <h4 className="st-text-white text-start mb-4">
          Become a better <span className="st-text-red">Story</span> Teller
        </h4>
        <div className="home-st-videos d-flex ">
          {videoData.map((video: iVideo, index: number) => (
            <div
              className="home-video-card st-cursor-pointer "
              key={index}
              onClick={() => openModal(video)}
            >
              <div
                className={`custom-video-player st-better-story-teller-thumb  ${
                  index === 2 ? "st-logo" : ""
                }`}
              >
                <img
                  className="better story teller thumb"
                  alt="thumb"
                  src={video.thumbnail}
                />
                <div className="custom-video-controls">
                  <div className="video-wrapper">
                    <img
                      src="/assets/video-icons/video-play-button.svg"
                      alt="video-play-button"
                      id="v-play-btn"
                    />
                  </div>
                </div>
              </div>
              <div className="thumb">
                <div className="home-video-card-details d-flex flex-row align-items-center justify-content-between">
                  <h4>{video.title}</h4>
                  <img
                    src="/assets/images/video-play-button.svg"
                    alt="video-play-button"
                    className="home-video-play-button1"
                    onClick={() => openModal(video)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default BetterStoryTellerCard;
