import { FunctionComponent, useState } from "react";
import { CarouselCardProps } from "../../../model/shared.model";
import { CONSTANTS } from "../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";
const CarouselCard: FunctionComponent<CarouselCardProps> = (
  props: CarouselCardProps
) => {
  const { isVertical, video, onPlay } = props;

  const [isExpand, setExpand] = useState(false);

  const getVideoDesc = () => {
    let description: string = video.description;
    if (description?.length > CONSTANTS.DESCRIPTION_LENGTH && !isExpand) {
      description =
        description.slice(0, CONSTANTS.DESCRIPTION_LENGTH - 20) + "...";
    }

    return description;
  };
  return (
    <>
      <div
        className={`st-carousel-video-card ${isExpand ? "st-card-expand" : ""}`}
      >
        {video?.media_url === null ? (
          <>
            {isVertical ? (
              <>
                <div className="st-thumb-wrapper st-placeholder">
                  <h4 className="st-placeholder-title">
                    Processing your story. Please wait
                  </h4>

                  <img
                    src="/assets/images/my-story-poster.png"
                    className="st-thumb st-placeholder-thumb"
                    alt={video.title}
                  />
                </div>
              </>
            ) : (
              <>
                <h4 className="st-placeholder-title">
                  Processing your story. Please wait
                </h4>

                <img
                  src="/assets/images/my-story-poster.png"
                  className="st-thumb st-placeholder-thumb"
                  alt={video.title}
                />
              </>
            )}
          </>
        ) : (
          <>
            {isVertical ? (
              <div
                className="st-thumb-wrapper st-thumb-vertical-wrapper"
                onClick={() => onPlay(video)}
              >
                <img
                  src="/assets/images/video-play-button.svg"
                  alt="video-play-button"
                  className="st-play-button"
                />
                <img
                  src={video.thumbnail}
                  className="st-thumb"
                  alt={video.title}
                />
              </div>
            ) : (
              <>
                <img
                  src="/assets/images/video-play-button.svg"
                  alt="video-play-button"
                  className="st-play-button"
                  onClick={() => onPlay(video)}
                />
                <img
                  src={video.thumbnail}
                  className="st-thumb"
                  alt={video.title}
                  onClick={() => onPlay(video)}
                />
              </>
            )}
          </>
        )}
        <div className="st-captions pt-0">
          <h3 className="st-title text-start">{video.title}</h3>
          <div
            className={`st-meta | ${
              !isVertical ? "d-flex justify-content-between" : "text-start"
            } `}
          >
            <div className="st-author"> {video.author.first_name}</div>
            <div className="st-category">
              {video.category_data?.map((cat: any, index: number) => {
                return (
                  <span className="badge bg-danger">{cat?.name || ""}</span>
                );
              })}
            </div>
            {isVertical && (
              <>
                {" "}
                <div className={`st-description `}>
                  {getVideoDesc()}{" "}
                  {video?.description?.length >
                    CONSTANTS.DESCRIPTION_LENGTH && (
                    <button
                      className="st-text-red px-0"
                      onClick={() => setExpand(!isExpand)}
                    >
                      {isExpand ? (
                        <>
                          Read Less
                          <FontAwesomeIcon
                            icon={faChevronCircleLeft}
                            className="st-text-red mx-2"
                          />
                        </>
                      ) : (
                        <>
                          Read More
                          <FontAwesomeIcon
                            icon={faChevronCircleRight}
                            className="st-text-red mx-2"
                          />
                        </>
                      )}
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CarouselCard;
