import React, { useContext, useState } from "react";
import SignInModal from "../../User/SignInModal/SignInModal";
import SignUpModal from "../../User/SignUpModal/SignUpModal";
import { useNavigate, useParams } from "react-router-dom";
import { CONSTANTS } from "../../../constants";
import { UserContext, useAuth } from "../../../context/userContext";
import API_SERVICES from "../../../services";
import commonService from "../../../services/common.service";
import { handleResponse } from "../../../services/apiConfig/api.response.service";
import ForgotPassword from "../../User/ForgotPassword/ForgotPassword";

function PromoBanner() {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSignUpModalOpen, setIsSignUpModalOpen] = useState<boolean>(false);
  const [isThanksModalOpen, setIsThanksModalOpen] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isSignInModalOpen, setIsSignInModalOpen] = useState<boolean>(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] =
    useState<boolean>(false);
  const { user, login }: any = useAuth();
  // const { login }: any = useContext(UserContext);
  let navigate = useNavigate();

  const getProfileDetails = (token: any) => {
    API_SERVICES.USER.GET_PROFILE_DETAILS()
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData?.ok) {
          login(responseData.data, false);
          toggleModal();
          setIsFetching(false);
          // navigate("/dashboard/profile");
        } else {
          setIsFetching(false);
        }
      })
      .catch(() => setIsFetching(false));
  };

  const handleForgotPassword = (formData: any) => {
    setIsFetching(true);

    API_SERVICES.USER.FORGOT_PASSWORD(formData)
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData?.ok) {
          setResetSuccess(true);
          setIsFetching(false);
        } else {
          commonService.toastService(
            commonService.flattenObjectToString(responseData.data),
            "error"
          );
          setIsFetching(false);
        }
      })
      .catch((e: any) => commonService.flattenObjectToString(e));
  };

  const handleSignIn = (formData: any) => {
    console.log("ab", formData);
    setIsFetching(true);
    API_SERVICES.USER.GET_ACCESS_TOKEN(formData)
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData?.ok) {
          const tokens: any = {};
          tokens[CONSTANTS.LOCAL_STORAGE.ACCESS_TOKEN] =
            responseData.data.access;
          tokens[CONSTANTS.LOCAL_STORAGE.REFRESH_TOKEN] =
            responseData.data.refresh;
          commonService.setLocalStorage(tokens);
          getProfileDetails(responseData.data);
          setIsModalOpen(false);
        } else {
          commonService.toastService(
            commonService.flattenObjectToString(responseData.data),
            "error"
          );
          setIsFetching(false);
        }
      })
      .catch((e: any) => commonService.flattenObjectToString(e));
  };

  const handleSignUp = (formData: any) => {
    setIsFetching(true);
    API_SERVICES.USER.REGISTER_USER(formData)
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData?.ok) {
          commonService.toastService("Registration Successful", "success");
          setIsSignUpModalOpen(false);
          setIsThanksModalOpen(true);
        } else {
          commonService.toastService(
            commonService.flattenObjectToString(responseData.data),
            "error"
          );
        }

        setIsFetching(false);
      })
      .catch(() => {
        commonService.toastService("Registration Failed", "error");
        setIsFetching(false);
      });
  };

  const toggleModal = () => {
    setResetSuccess(false);
    setIsModalOpen(!isModalOpen);
    setIsSignUpModalOpen(false);
    setIsSignInModalOpen(false);
    setIsForgotPasswordModalOpen(false);
  };

  const toggleModalType = (view: string) => {
    setIsModalOpen(true);
    setIsSignUpModalOpen(view === "signup");
    setIsSignInModalOpen(view === "signin");
    setIsForgotPasswordModalOpen(view === "forgotpassword");
  };

  return (
    <div>
      <div className="footer1 | d-flex flex-column align-items-center justify-content-center">
        <img
          src="/assets/images/footer-bg-gradient.svg"
          alt="footer-grdient-overlay"
          id="footer-gradient-overlay"
        />
        <div className="container">
          <h2>What's Your Story? The world wants to hear it.</h2>
          <p>
            When you're ready to tell a story, you can use the camera on your
            desktop, laptop, or phone.
          </p>
          <p>
            Just
            <span
              onClick={() => {
                if (user?.first_name) {
                  navigate("/dashboard/new-story");
                } else {
                  toggleModalType("signin");
                }
                console.log("abc", user);
              }}
              className="mx-1"
              data-bs-toggle="modal"
              data-bs-target="#login_popup"
            >
              click here
            </span>
            {user?.first_name ? "to tell your story." : "to sign in."}
          </p>
        </div>
      </div>
      {isSignUpModalOpen ? (
        <SignUpModal
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          handleSignIn={handleSignUp}
          toggleModalType={(x: string) => toggleModalType(x)}
        />
      ) : isForgotPasswordModalOpen ? (
        <ForgotPassword
          isModalOpen={isModalOpen}
          isSuccess={resetSuccess}
          toggleModal={toggleModal}
          handleSignIn={handleForgotPassword}
          toggleModalType={(x: string) => toggleModalType(x)}
        />
      ) : isSignInModalOpen ? (
        <SignInModal
          handleSignIn={handleSignIn}
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          toggleModalType={(x: string) => toggleModalType(x)}
        />
      ) : null}
    </div>
  );
}

export default PromoBanner;
