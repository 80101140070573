import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { usePrevious } from "../../../hooks/usePrevious";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { useAuth } from "../../../context/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

interface ResponsiveNavbarProps {
  isOpen: boolean;
}

const ResponsiveNavbar: FunctionComponent<ResponsiveNavbarProps> = (
  props: ResponsiveNavbarProps
) => {
  const { isOpen } = props;
  const [navOpen, setNavOpen] = useState(false);
  const isFirst = useRef(false);
  const { user }: any = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    isFirst.current = true;
  }, []);

  useEffect(() => {
    if (!isFirst.current) {
      toggle(!navOpen);
    } else isFirst.current = false;
  }, [isOpen]);

  useEffect(() => {
    if (navOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [navOpen]);

  const toggle = (state: boolean) => {
    setNavOpen(state);
  };

  const handleClick = () => {
    if (!user?.first_name) {
      navigate("/home/login");
    } else {
      navigate("/dashboard/new-story");
    }
    toggle(false);
  };

  return (
    <>
      <div className={`st-resp-navbar ${navOpen && "open"}`}>
        <button
          type="button"
          className="st-close-btn"
          aria-label="Close"
          onClick={() => toggle(false)}
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            className="st-text-red"
            size="2xl"
          />
        </button>
        <div className="resp-nav | flex-fill">
          <Nav className="m-0 d-flex flex-fill flex-column st-nav w-100 justify-content-between ">
            <NavItem className="my-3">
              <NavLink to="/">Home</NavLink>
            </NavItem>
            <NavItem className="my-3">
              <NavLink to="/village">
                <img
                  className="navbar-img mt-1"
                  src="/assets/images/storytalk-village-logo.png"
                  alt="navbar-img"
                />
              </NavLink>
            </NavItem>

            <NavItem className="my-3">
              <div onClick={handleClick}>
                <img
                  className="navbar-svg mt-1"
                  src="/assets/images/whatsyourstory.svg"
                  alt="navbar-img"
                />
              </div>
            </NavItem>

            <NavItem className="my-3">
              <NavLink to="/faq">FAQ</NavLink>
            </NavItem>
            <NavItem className="my-3">
              <NavLink to="/about">About</NavLink>
            </NavItem>
            <NavItem className="my-3">
              <NavLink to="/contact">Contact</NavLink>
            </NavItem>
          </Nav>
        </div>
        <div className="st-footer text-center">
          <img src="/assets/images/storytalk-logo-reversed-nav.png" alt="" />
          <p>
            Copyright © 2023 <br />
            All Rights Reserved by Storyland Partners LLC. <br />
            <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink> |
            support@storylandpartners.com
          </p>
        </div>
      </div>
    </>
  );
};

export default ResponsiveNavbar;
