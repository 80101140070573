import { FunctionComponent, useEffect, useRef, useState } from "react";
import { CategoryDetailsPageProps } from "../../model/category.model";
import Layout from "../../layouts/Layout";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import API_SERVICES from "../../services";
import PageLoader from "../../components/shared/PageLoader/PageLoader";
import MyStories from "../../components/Profile/MyStories/MyStories";
import AllStories from "../../components/Village/AllStories/AllStories";
import { storiesItem } from "../../model/shared.model";
import CategoryNavbar from "../../components/Village/CategoryNavbar/CategoryNavbar";
import { handleResponse } from "../../services/apiConfig/api.response.service";
import VideoPreviewModal from "../../components/shared/VideoPreviewModal/VideoPreviewModal";
import PromoBanner from "../../components/shared/PromoBanner/PromoBanner";
import { Alert } from "reactstrap";

const CategoryDetailsPage: FunctionComponent<CategoryDetailsPageProps> = () => {
  const { categoryId, catName } = useParams();
  const navigate = useNavigate();

  const [isFetchingCategories, setFetchingCategories] = useState(false);
  const [catDetails, setCatDetails] = useState<any>({});
  const allResponseData: any = useRef<any>({});
  const [isFetchingFeatured, setFetchingFeatured] = useState(false);
  const [isFetchingRecent, setFetchingRecent] = useState(false);
  const [isFetchingAllStories, setFetchingAllStories] = useState(false);
  const [featuredStories, setFeaturedStories] = useState<any>([]);
  const [allStories, setAllStories] = useState<any>([]);
  const [recentlyAddedStories, setRecentlyAddedStories] = useState<any>([]);
  const [currentVideo, setCurrentVideo] = useState<storiesItem | null>(null);
  const [allCategories, setAllCategories] = useState<any>([]);
  const [isCatNavOpen, setCatNavOpen] = useState(false);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState<boolean>(false);

  const fetchData: any = useRef({
    storyNextPage: 0,
    storyTotalCount: 0,
    storyNextPageUrl: 0,
    isFetching: false,
  });

  useEffect(() => {
    fetchData.current.storyNextPage = 0;
    window.scrollTo(0, 0);
    loadCategories();
    fetchData.current.isFetching = true;
    loadItems();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!fetchData.current.isFetching) {
      loadCategories();
      loadItems();
      fetchData.current.isFetching = true;
    }
  }, [categoryId]);

  const handleRedirection = (catId: number, catName: string) => {
    setFeaturedStories([]);
    setRecentlyAddedStories([]);
    setAllStories([]);
    toggleCategoryNav();
    navigate(`/village/category/${catId}/${catName}`);
  };

  const handlePlay = (video: storiesItem) => {
    setCurrentVideo(video);
    setPreviewModalOpen(true);
  };

  const loadAllStories = (pageNo?: number) => {
    const payload: any = {
      category: Number(categoryId),
      featured_carousel: 0,
      is_featured: 0,
    };
    setFetchingAllStories(true);
    API_SERVICES.VIDEO.GET_STORY_LIST(payload, pageNo).then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData.ok) {
        const updatedStories: Array<storiesItem> = [...allStories];
        const newStories: Array<storiesItem> = responseData.data.results;

        if (data.data.next !== null) {
          const page: string = data.data.next.split("=")[1];
          fetchData.current.storyNextPage = page;
        }
        fetchData.current.storyTotalCount = data.data.count;
        fetchData.current.storyNextPageUrl = data.data.next;
        setFetchingAllStories(false);
        updatedStories.push(...newStories);
        setAllStories(updatedStories);
        fetchData.current.isFetching = false;
      }
    });
  };

  const loadAllStoriesMore = () => {
    if (fetchData.current.storyNextPageUrl) {
      loadAllStories(fetchData.current.storyNextPage);
    }
  };

  const loadCategories = (page?: number) => {
    setFetchingCategories(true);
    API_SERVICES.VIDEO.GET_CATEGORIES(page).then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData.ok) {
        allResponseData.current["allCategories"] = data.data.results;
        const currentCatDetails: any = data.data.results.find(
          (cat: any) => cat.id === Number(categoryId)
        );
        setAllCategories(data.data.results);
        setCatDetails(currentCatDetails);
        setFetchingCategories(false);
      }
    });
  };

  const loadItems = () => {
    setFetchingAllStories(true);
    setFetchingCategories(true);
    setFetchingFeatured(true);
    setFetchingRecent(true);

    loadStories(
      {
        category: Number(categoryId),
        featured_carousel: 0,
        is_featured: 1,
      },
      setFeaturedStories,
      "featured"
    );

    loadStories(
      {
        category: Number(categoryId),
        featured_carousel: 0,
        is_featured: 0,
      },
      setRecentlyAddedStories,
      "recent"
    );
    loadCategories();
    loadAllStories();
  };

  const loadStories = (data: any, setData: Function, area: string) => {
    API_SERVICES.VIDEO.GET_STORY_LIST(data).then((data: any) => {
      if (area === "featured") setFetchingFeatured(false);
      else setFetchingRecent(false);
      setData(data.data);
    });
  };

  const noStoryNotice: any = () => {
    return (
      <Alert color="warning text-start">
        No stories added on this category
      </Alert>
    );
  };

  const toggleCategoryNav = () => {
    setCatNavOpen(!isCatNavOpen);
  };

  return (
    <>
      <PageLoader isLoading={isFetchingCategories} />
      <Layout containerClass="st-village-page">
        <Helmet>
          <title>Story talk | Category | {catName} </title>
        </Helmet>

        <CategoryNavbar
          isOpen={isCatNavOpen}
          categories={allCategories}
          toggleNav={toggleCategoryNav}
          handleRedirection={handleRedirection}
        />
        <VideoPreviewModal
          video={currentVideo}
          isOpen={isPreviewModalOpen}
          closeModal={() => setPreviewModalOpen(false)}
        />
        <div className="st-cat-banner">
          {catDetails?.image && (
            <>
              <div className="st-slider-content">
                <div className=" align-items-center d-flex w-100">
                  <div className="container m-auto">
                    <h2 className="st-tile mb-4">{catDetails?.name || ""}</h2>
                    <div className="st-text">
                      {catDetails?.description || ""}
                    </div>
                  </div>
                </div>
              </div>
              <img className="st-img" src={catDetails.image} />
            </>
          )}
        </div>

        <MyStories
          stories={featuredStories}
          isFetching={isFetchingFeatured}
          onPlay={handlePlay}
          title="Featured Stories"
          containerClass="border-top pb-5"
          noContentAlert={noStoryNotice}
        />
        <MyStories
          stories={recentlyAddedStories}
          isFetching={isFetchingRecent}
          onPlay={handlePlay}
          title="Recently added Stories"
          containerClass="border-top pb-5 st-bg-coffee"
          noContentAlert={noStoryNotice}
        />
        <div className="py-3">
          <AllStories
            isFetching={isFetchingAllStories}
            loadMore={loadAllStoriesMore}
            stories={allStories}
            onPlay={handlePlay}
            showLoadMore={fetchData.current.storyNextPageUrl}
            title={"All Stories - " + catName}
            toggleCategoryNav={toggleCategoryNav}
          />
        </div>
        <PromoBanner />
      </Layout>
    </>
  );
};

export default CategoryDetailsPage;
