import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
function Footer() {
  const handleLogoClick = () => {
    window.scrollTo(0, 0);
  };
  const year = new Date();
  return (
    <div>
      <div className="footer2 container ">
        <div className="footer2-content  footer-border">
          <NavLink to={"/"}>
            <img
              src="/assets/images/storytalk-logo-small.svg"
              alt="storytalk-logo-small"
              onClick={handleLogoClick}
            />
          </NavLink>
        </div>

        <div className="footer2-content">
          <p>
            Copyright &copy; {year.getFullYear()} All Rights Reserved by
            Storyland Partners LLC.
          </p>
        </div>
        <div className="footer2-content">
          <p>
            {" "}
            <NavLink to={"/privacy-policy"}>Privacy Policy</NavLink> |{" "}
            <a href="mailto:support@storylandpartners.com">
              support@storylandpartners.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
