import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import SignInModal from "../User/SignInModal/SignInModal";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Nav,
  NavItem,
} from "reactstrap";
import { useAuth } from "../../context/userContext";
import { useState } from "react";
import ResponsiveNavbar from "./Navbar/ResponsiveNavbar";
import commonService from "../../services/common.service";
type HeaderPropType = {
  onSignInClick: Function;
  children?: React.ReactNode;
};

function Header({ onSignInClick }: HeaderPropType) {
  const { user, logout }: any = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const handleSignInClick = () => {
    onSignInClick();
  };
  const toggleDropdown = () =>
    setDropdownOpen((prevState: boolean) => !prevState);

  const handleClickOut = () => {
    commonService.toastService(
      "You have been successfully logged out",
      "success"
    );
    logout();
  };

  return (
    <>
      <ResponsiveNavbar isOpen={isNavbarOpen} />
      <div id="nav-wrapper" className="st-nav-wrapper ">
        <div className="container d-flex py-3 py-xl-4 px-0 px-xl-3 justify-content-between align-items-center">
          <img
            className="leftlg"
            data-bs-toggle="modal"
            data-bs-target="#navbar-menu"
            src="/assets/images/Group5@2x.png"
            alt="leftlg"
            onClick={() => setIsNavbarOpen(!isNavbarOpen)}
          />
          <div className="d-flex st-logo-wrapper d-xl-block">
            <NavLink to="/" className="st-logo">
              <img
                className="nav-img"
                src="/assets/images/storytalk-logo-reversed-nav.png"
                alt="nav-img"
              />
            </NavLink>
          </div>
          <div className="d-flex ms-xl-5 flex-nowrap flex-xl-fill justify-content-between">
            <div
              className="nav-links d-flex align-items-center px-4 flex-fill"
              id="navLinks"
            >
              <Nav className="m-0 flex-nowrap flex-fill align-items-center">
                <NavItem>
                  <NavLink to="/village">
                    <img
                      className="navbar-img mt-1"
                      src="/assets/images/storytalk-village-logo.png"
                      alt="navbar-img"
                    />
                  </NavLink>
                </NavItem>
                <NavItem>
                  {!user?.first_name ? (
                    <div
                      className="st-cursor-pointer"
                      onClick={handleSignInClick}
                    >
                      <img
                        className="navbar-svg mt-1"
                        src="/assets/images/whatsyourstory.svg"
                        alt="navbar-img"
                      />
                    </div>
                  ) : (
                    <NavLink to="/dashboard/new-story">
                      <img
                        className="navbar-svg mt-1"
                        src="/assets/images/whatsyourstory.svg"
                        alt="navbar-img"
                      />
                    </NavLink>
                  )}
                </NavItem>
                <NavItem>
                  <NavLink to="/faq">FAQ</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/about">About</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/contact">Contact</NavLink>
                </NavItem>
              </Nav>
            </div>
            {!user?.first_name && (
              <>
                <div className="sign resp-button d">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#login_popup"
                    type="button"
                    className="st-btn st-btn-primary"
                    style={{ margin: 0 }}
                    onClick={handleSignInClick}
                  >
                    Sign In
                  </button>
                </div>
                <img
                  className="rightlg"
                  data-bs-toggle="modal"
                  data-bs-target="#login_popup"
                  src="/assets/images/Group4@2x.png"
                  alt="rightlg"
                  onClick={handleSignInClick}
                />
              </>
            )}
          </div>

          {user?.first_name && (
            <div className="st-header-user-info d-flex align-items-center">
              <div className="nav-link st-username me-4 d-none d-md-block">
                Hi {user?.first_name}
              </div>
              <Dropdown
                isOpen={dropdownOpen}
                toggle={toggleDropdown}
                direction="down"
              >
                <DropdownToggle
                  className="nav-link | st-username st-cursor-pointer"
                  // tag="a"
                  // href="#"
                >
                  <div className="st-avatar-wrapper">
                    {user?.image ? (
                      <img
                        src={user?.image}
                        alt={user?.first_name}
                        className="st-avatar rounded-circle"
                      />
                    ) : (
                      <div className="st-placeholder rounded-circle"></div>
                    )}
                  </div>
                </DropdownToggle>
                <DropdownMenu className="st-dropdown | right p-0 mt-2 rounded-0">
                  <DropdownItem href="/dashboard/profile" tag="a">
                    View profile
                  </DropdownItem>

                  <DropdownItem onClick={handleClickOut}>Logout</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Header;
