import Layout from "../../layouts/Layout";
import Mission from "../../components/Mission/Mission";
import Avatar from "../../components/Avatar/Avatar";
import PromoBanner from "../../components/shared/PromoBanner/PromoBanner";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>Story talk | About us</title>
      </Helmet>
      <Layout>
        <Mission />
        <Avatar />
        <PromoBanner />
      </Layout>
    </div>
  );
};

export default AboutUs;
