import React from "react";
import Layout from "../../layouts/Layout";
import FaqBody from "../../components/FaqBody/FaqBody";
import { Helmet } from "react-helmet";
import PromoBanner from "../../components/shared/PromoBanner/PromoBanner";
function Faq() {
  return (
    <div>
      <Helmet>
        <title>Story talk | FAQ</title>
      </Helmet>
      <Layout>
        <FaqBody />
        <PromoBanner />
      </Layout>
    </div>
  );
}

export default Faq;
