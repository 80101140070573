import { FormFeedback, Input } from "reactstrap";
import { FormInputProps } from "../../model/contact.formInput.model";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const defaultProps: any = {
  type: "text",
  id: "",
  placeholder: "",
};
function FormInput(props: FormInputProps) {
  const { actions, containerClass, formData, inputClass } = props;
  const { invalid, type, id, placeholder }: any = {
    ...defaultProps,
    ...formData,
  };
  const { handleChange, handleBlur } = actions;
  const [viewPassword, setViewPassWord] = useState(false);

  const inpType = type === "password" ? (viewPassword ? "text" : type) : type;
  return (
    <div className={`form-floating   ${containerClass && containerClass}`}>
      <Input
        type={inpType}
        className={`form-control ${inputClass && inputClass}`}
        id={id}
        placeholder={placeholder}
        onChange={(e: any) => {
          handleChange(e.target.value, formData);
        }}
        onBlur={(e: any) => {
          handleBlur?.(e.target.value, formData);
        }}
        value={formData?.value || ""}
        {...props?.inputProps}
        invalid={invalid?.length > 0}
      />

      {invalid?.length > 0 && <FormFeedback tooltip>{invalid}</FormFeedback>}
      {type === "password" && formData?.value !== "" && (
        <div
          className="st-eye st-cursor-pointer"
          onClick={() => setViewPassWord(!viewPassword)}
        >
          {viewPassword ? (
            <FontAwesomeIcon
              icon={faEyeSlash}
              className="st-text-red"
              size="lg"
            />
          ) : (
            <FontAwesomeIcon icon={faEye} className="st-text-red" size="lg" />
          )}
        </div>
      )}
      <label htmlFor={formData.id}>
        {formData.label}
        {formData.required && (
          <span className="mx-1" style={{ color: "red" }}>
            *
          </span>
        )}
      </label>
    </div>
  );
}
export default FormInput;
