import React from "react";
import { pageBannerProps } from "../../../model/shared.model";

function PageBanner(props: pageBannerProps) {
  const { children, containerClass, wrapperClass, image } = props;
  return (
    <div id="page-banner" className={wrapperClass && wrapperClass}>
      <div className="st-banner-wrapper position-relative">
        <img src={image} alt="st-banner-img" className="st-banner-img w-100" />
        <div className={containerClass}>{children}</div>
      </div>
    </div>
  );
}

export default PageBanner;
