import { useState } from "react";
import FormInput from "../Contact/FormInput";
import { formInputController } from "../../model/contact.formInput.model";
import API_SERVICES from "../../services";
import { toast } from "react-hot-toast";
import { minLength, required, validate } from "../../helpers/validations";

const INITIAL_FORM_DATA = [
  {
    type: "text",
    placeholder: "Your Name",
    label: "Your name",
    id: "name",
    value: "",
    invalid: "",
    validations: [required()],
    required: true,
  },
  {
    type: "email",
    placeholder: "Your Email",
    label: "Your email",
    id: "email",
    value: "",
    invalid: "",
    validations: [required()],
    required: true,
  },
  {
    type: "text",
    placeholder: "Your Subject",
    label: "Your subject",
    id: "subject",
    validations: [required()],
    value: "",
    invalid: "",
    required: true,
  },
  {
    type: "textarea",
    placeholder: "Your Question",
    label: "Your question",
    id: "question",
    validations: [required(), minLength(20)],
    value: "",
    invalid: "",
    required: true,
    inputClass: "st-textarea",
    containerClass: "st-form-floating-textarea",
  },
];

function ContactBanner() {
  const [formData, setFormData] = useState(INITIAL_FORM_DATA);

  const handleChange = (
    id: string,
    value: string,
    form: formInputController
  ) => {
    setFormData((formData) =>
      formData.map((data) =>
        data.id === id
          ? {
              ...data,
              value,
            }
          : data
      )
    );
  };

  const handleBlur = (id: string, value: string, form: formInputController) => {
    setFormData((formData) =>
      formData.map((data) =>
        data.id === id
          ? {
              ...data,
              invalid: validate(form.validations, value),
            }
          : data
      )
    );
  };

  const handleSubmit = () => {
    let isFormValid = true;
    const newFormData = formData.map((data) => {
      const error = data.invalid || validate(data.validations, data.value);
      if (error) isFormValid = false;
      return {
        ...data,
        invalid: error,
      };
    });

    if (!isFormValid) {
      setFormData(newFormData);
      return;
    }

    const formValues: { [key: string]: string } = {};
    formData.forEach((data) => (formValues[data.id] = data.value));
    API_SERVICES.USER.CONTACT_US(formValues)
      .then((data: any) => {
        if (!data.ok) {
          throw new Error("something went wrong");
        }
        toast.success("Updated successfully");
        setFormData(INITIAL_FORM_DATA);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  return (
    <section className="contact-main">
      <div className="container ">
        <div className="row gx-5 | video-modal-row">
          <div className="col-lg-6">
            <div className="left">
              <form action="home.html">
                {formData.map((data: any, index: any) => (
                  <FormInput
                    key={index}
                    formData={data}
                    actions={{
                      handleChange: handleChange.bind(null, data.id),
                      handleBlur: handleBlur.bind(null, data.id),
                    }}
                    inputClass={data?.inputClass}
                    containerClass={data?.containerClass}
                  />
                ))}
              </form>
            </div>
            <button
              type="submit"
              className="btn btn-primary resp-button-full"
              onClick={handleSubmit}
            >
              SUBMIT
            </button>
          </div>
          <div className="col-lg-6 mb-4 mb-md-0">
            <div className="right_contact">
              <img src="/assets/images/storytalk-logo-reversed.png" alt="" />

              <p>
                General Queries: <br />
                <a href="mailto:hello@storylandpartners.com">
                  hello@storylandpartners.com
                </a>
              </p>
              <p>
                Technical Queries:
                <br />
                <a href="mailto:support@storylandpartners.com">
                  support@storylandpartners.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ContactBanner;
