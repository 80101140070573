import React, { useState } from "react";
import Layout from "../../layouts/Layout";
import HomeSlider from "../../components/Home/HomeSlider";
import BetterStoryTellerCard from "../../components/shared/BetterStoryTellerCard/BetterStoryTellerCard";
import VillageBanner from "../../components/shared/VillageBanner";
import PromoBanner from "../../components/shared/PromoBanner/PromoBanner";
import { Helmet } from "react-helmet";
import VideoPreviewModal from "../../components/shared/VideoPreviewModal/VideoPreviewModal";
import { storiesItem } from "../../model/shared.model";
import WelcomeCard from "../../components/Home/WelcomeCard/WelcomeCard";

const Home = () => {
  const [isPreviewModalOpen, setPreviewModalOpen] = useState<boolean>(false);
  const [currentVideo, setCurrentVideo] = useState<storiesItem | null>(null);

  const handlePlay = (video: storiesItem) => {
    setCurrentVideo(video);
    setPreviewModalOpen(true);
  };

  return (
    <div>
      <Helmet>
        <title>Story talk | Tell your story</title>
      </Helmet>

      <VideoPreviewModal
        video={currentVideo}
        isOpen={isPreviewModalOpen}
        closeModal={() => setPreviewModalOpen(false)}
      />
      <Layout>
        <WelcomeCard />
        <HomeSlider />
        <VillageBanner />
        <BetterStoryTellerCard onPlay={handlePlay} />
        <PromoBanner />
      </Layout>
    </div>
  );
};

export default Home;
