import { FunctionComponent } from "react";
import { ProgressBarProps } from "../../../model/shared.model";

const ProgressBar: FunctionComponent<ProgressBarProps> = (
  props: ProgressBarProps
) => {
  return (
    <>
      <div className="loading-black flex-1 w-100">
        <div className="loading-orange" style={{ width: props.fill }}></div>
      </div>
    </>
  );
};

export default ProgressBar;
