import { useState } from "react";
import { Modal } from "reactstrap";
import { SignInModalProps } from "../../../model/user.model";
import Divider from "../../shared/Divider/Divider";
import FormInput from "../../Contact/FormInput";
import { formInputController } from "../../../model/contact.formInput.model";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

function SignInModal(props: SignInModalProps) {
  const { handleSignIn, isModalOpen, toggleModal, toggleModalType } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState("");
  const [isPasswordInvalid, setIsPasswordInvalid] = useState("");

  const handleChange = (value: string, itemData: formInputController) => {
    if (itemData.id === "email") {
      setEmail(value);
      setIsEmailInvalid("");
    } else {
      setPassword(value);
      setIsPasswordInvalid("");
    }
  };
  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    if (email.trim() === "" && password.trim() === "") {
      setIsEmailInvalid("This field is required");
      setIsPasswordInvalid("This field is required");
      return;
    }
    if (email.trim() === "") {
      setIsEmailInvalid("This field is required");
    } else if (password.trim() === "") {
      setIsPasswordInvalid("This field is required");
    } else {
      handleSignIn({ email, password });
    }
  };

  const signInInputs: formInputController[] = [
    {
      id: "email",
      label: "Registered email",
      placeholder: "Registered Email",
      value: email,
      type: "email",
      required: true,
      invalid: isEmailInvalid,
    },
    {
      id: "password",
      type: "password",
      label: "Password",
      placeholder: "Password",
      value: password,
      required: true,
      invalid: isPasswordInvalid,
    },
  ];

  return (
    <Modal
      className="st-custom-modal"
      isOpen={isModalOpen}
      toggle={toggleModal}
      size="lg"
      centered
    >
      <div className="modal-content">
        <div className="modal-header pb-0">
          <div className="form-head mt-5">
            <img src="/assets/images/storytalk-village-logo.svg" alt="" />
            <h2>Sign In</h2>
          </div>
          <button
            type="button"
            className="st-close-btn"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={toggleModal}
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              className="st-text-red"
              size="2xl"
            />
          </button>
        </div>
        <div className="modal-body">
          <form className="st-sign-in-form" onSubmit={handleLogin}>
            <div className="account">
              <span>Don't have an account?</span>
              <span
                className="signup-btn"
                onClick={() => {
                  toggleModalType("signup");
                }}
              >
                Sign up
              </span>
            </div>
            <Divider className="my-4" text="OR" />
            {signInInputs.map(
              (formData: formInputController, index: number) => (
                <FormInput
                  key={index}
                  formData={formData}
                  actions={{ handleChange: handleChange }}
                  containerClass={"st-text-sand"}
                />
              )
            )}

            <div className="keep-forgot">
              <div className="mb-3 form-check keep"></div>
              <div
                className="st-cursor-pointer st-text-red"
                onClick={() => toggleModalType("forgotpassword")}
              >
                Forgot password
              </div>
            </div>
            <div className="submit">
              <button
                type="submit"
                className="st-btn st-btn-primary"
                style={{ margin: 0 }}
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default SignInModal;
